import { Dispatch, SetStateAction } from 'react';
import { Input, Space, Typography } from 'antd';

import styles from './review-modal.module.css';

import { REVIEW_MODAL } from '~/constants/review-modal';

const { TextArea } = Input;

type ReviewModalType = {
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
};

export const ReviewModal = ({ value, setValue }: ReviewModalType) => (
    <Space className={styles.reviewModal} direction='vertical' align='center'>
        <Typography.Title level={3}>{REVIEW_MODAL.title}</Typography.Title>
        <Typography.Text>{REVIEW_MODAL.text}</Typography.Text>
        <TextArea
            value={value}
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder={REVIEW_MODAL.maskInput}
            onChange={(e) => setValue(e.target.value)}
            className={styles.reviewModalTextArea}
        />
    </Space>
);
