import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppTabState = typeof initialState;

const initialState = {
    isOpenModalForMoveFromApplicationTab: false,
};

export const appTabSlice = createSlice({
    name: 'appTab',
    initialState,
    reducers: {
        setOpenModalForMoveFromApplicationTab: (state, actions: PayloadAction<boolean>) => {
            state.isOpenModalForMoveFromApplicationTab = actions.payload;
        },
    },
    selectors: { appTabSelector: (state) => state },
});

export const { setOpenModalForMoveFromApplicationTab } = appTabSlice.actions;
export const { appTabSelector } = appTabSlice.selectors;

export const appTabReducer = appTabSlice.reducer;
