import { MutableRefObject, useEffect } from 'react';

export const useClickOutside = (
    ref: MutableRefObject<HTMLInputElement | null>,
    callback: () => void,
) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick as () => void);

        return () => {
            document.removeEventListener('mousedown', handleClick as () => void);
        };
    });
};
