import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';

import styles from './sidebar.module.css';

import { items } from '~/components/check-and-sign-certificate/sidebar/constants/items';
import { KEYS_FOR_TAB } from '~/components/check-and-sign-certificate/types/keys-for-tab';
import { AccountTab } from '~/constants/account-tab';
import { ROUTES } from '~/constants/routes';

export const Sidebar = () => {
    const { tabName, tabCheckCertificate } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const defaultItem = KEYS_FOR_TAB.checkCertificate;

    const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);

    const handleClick = useCallback(
        ({ key }: { key: string }) => {
            if (tabName === AccountTab.checkCertificateTab) {
                navigate(`${ROUTES.privateRoom}/${tabName}/${key}`);
            }
        },
        [navigate, tabName],
    );

    useEffect(() => {
        if (pathname.split('/').length === 3) {
            handleClick({ key: defaultItem });
            setSelectedKeys([defaultItem]);
        } else {
            setSelectedKeys([tabCheckCertificate ?? '']);
        }
    }, [defaultItem, handleClick, pathname, tabCheckCertificate]);

    return (
        <Sider className={styles.sidebar} width={350}>
            <Menu
                onClick={handleClick}
                className={styles.menu}
                theme='light'
                mode='inline'
                selectedKeys={selectedKeys}
                items={items}
            />
        </Sider>
    );
};
