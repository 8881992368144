// константы должны быть аналогичными как и для cadesplugin_api.js - при изменении синхронизировать

import { ModalTextVariant } from '~/constants/modal';

export const ERROR_REASONS = {
    EXTENSION: 'extension',
    PLUGIN: 'plugin',
    CSP: 'csp',
} as const;

export const CADES_PLUGIN_ECHO_REQUEST_MESSAGE = 'cadesplugin_echo_request';

export const CADES_PLUGIN_LOAD_ERROR_MESSAGE = 'cadesplugin_load_error';

type ModalExtentionContentType = {
    text: string;
    title: string;
};

export const MODAL_VARIANT_FOR_PLUGIN: Record<string, ModalExtentionContentType> = {
    [ERROR_REASONS.EXTENSION]: {
        text: ModalTextVariant.needExtension,
        title: ModalTextVariant.needExtensionTitle,
    },
    [ERROR_REASONS.PLUGIN]: {
        text: ModalTextVariant.needPlugin,
        title: ModalTextVariant.needPluginTitle,
    },
    [ERROR_REASONS.CSP]: {
        text: ModalTextVariant.needCSP,
        title: ModalTextVariant.needCSPTitle,
    },
};
