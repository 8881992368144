export enum TimeFormat {
    Ru = 'ru-RU',
}

export const changeDateFormat = (timestamp?: string | number | null) => {
    if (!timestamp) return '-';

    if (Date.parse(String(timestamp))) {
        const date = new Date(timestamp);

        return `${date.toLocaleDateString(TimeFormat.Ru)}`;
    }

    return 'Неверный формат даты';
};
