import { ModalTextVariant } from '../../constants/modal';
import { getModalErrorText } from '../get-modal-error-text';

import { fetchCurrentCertificateData } from './fetch-current-certificate-data';

export const revokeCertificateUtils = async (thumbprint: string, reason: number) => {
    const { enrolljs } = window;

    try {
        const certificateData = await fetchCurrentCertificateData(thumbprint);

        if (certificateData && typeof certificateData === 'object') {
            const signatureForRevoke: string = await enrolljs.createRevRequest(
                certificateData.serialNumber, // серийный номер отзываемого сертификата в в шестнадцатитеричном формате.
                '', // issuerName, не обязательный, должен быть задан один из параметров issuerName или thumbprint.
                thumbprint, // Отпечаток отзываемого сертификата в шестнадцатитеричном формате.
                reason, // Причина отзыва из модалки
                false, // Подписать запрос на сертификат.
                null, // моковый Отпечаток сертификата оператора.
            );

            return { text: signatureForRevoke, isError: false, title: '' };
        }

        return certificateData
            ? getModalErrorText(certificateData)
            : { text: ModalTextVariant.keyOrContainerUndefined, isError: true, title: '' };
    } catch (e) {
        return getModalErrorText(e);
    }
};
