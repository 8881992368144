import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import { RequireAuth } from '../require-auth';

import styles from './main-layout.module.css';

import { Header } from '~/shared/header';

const { Content } = Layout;

export const MainLayout = () => (
    <RequireAuth>
        <Layout className={styles.wrapper}>
            <Header />
            <Content>
                <Outlet />
            </Content>
        </Layout>
    </RequireAuth>
);
