import { Card, Table } from 'antd';

import { returnArrayForTable } from './constants/return-array-for-table';
import { returnColumns } from './constants/return-columns';

import { SignatureInfo } from '~/rtk-queries/types/certificate-verification';

export const AdditionalInformation = ({ signatureInfo }: { signatureInfo: SignatureInfo }) => (
    <Card>
        <Table
            dataSource={returnArrayForTable(signatureInfo)}
            columns={returnColumns()}
            pagination={false}
            showHeader={false}
            scroll={{ x: 200 }}
        />
    </Card>
);
