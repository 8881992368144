import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { useAppSelector } from '~/hooks';
import {
    useLazyGetDraftQuery,
    useRemoveDraftMutation,
} from '~/rtk-queries/endpoints/certificates-list';
import { CreateUserInfo } from '~/rtk-queries/types/certificates';
import { draftSelector } from '~/store/draft/get-draft-slice';
import { getLocalStorageItem } from '~/utils/local-storage';

export const useDraft = () => {
    const { tabName } = useParams();
    const storedDraftData = getLocalStorageItem(LOCAL_STORAGE.draftData);
    const [draft, setDraft] = useState<CreateUserInfo | null>(null);
    const draftData = useAppSelector(draftSelector);

    const [getDraft, { data, isFetching: isFetchingDraftInfo }] = useLazyGetDraftQuery();

    const [removeDraft, { isLoading: isRemovingDraft }] = useRemoveDraftMutation();

    const deleteDraft = useCallback(async () => {
        const { RequestId } = JSON.parse(storedDraftData || '');

        await removeDraft({ RequestId });
    }, [removeDraft, storedDraftData]);

    useEffect(() => {
        if (!storedDraftData) {
            setDraft(null);

            return;
        }

        const { RequestId } = JSON.parse(storedDraftData);

        if (draftData.userInfoTemp) {
            setDraft(draftData.userInfoTemp);
        } else {
            getDraft({ RequestId });

            setDraft(data?.userInfoTemp || null);
        }
    }, [tabName, draftData, getDraft, data, storedDraftData]);

    return {
        draft,
        isDraftInteractionInProgress: isFetchingDraftInfo || isRemovingDraft,
        deleteDraft,
    };
};
