export const findChildrenByAttributeValue = (
    ref: HTMLElement,
    attributeName: string,
    foundElements: HTMLElement[] = [],
): HTMLElement[] | null => {
    if (!ref || !ref.children) return foundElements;

    const { children } = ref;

    for (let i = 0; i < children.length; i++) {
        const child = children[i] as HTMLElement;

        if (child.getAttribute && child.getAttribute(attributeName)) {
            foundElements.push(child);
        }
        findChildrenByAttributeValue(child, attributeName, foundElements);
    }

    return foundElements;
};
