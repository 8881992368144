import { Fragment, ReactElement, useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';

import { returnCertificatesOptions } from '../helpers/return-certificates-options';
import { SignDocumentFields, SignedFormTypes } from '../types/form-types';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ERROR_TEXTS, ModalTextVariant } from '~/constants/modal';
import { PluginError } from '~/constants/plugin';
import { SignDocumentText } from '~/constants/sign-document';
import { useAppDispatch } from '~/hooks';
import { useCheckCadesExtension } from '~/hooks/use-check-cades-extension';
import { setPopupAlert } from '~/store/auth/auth-slice';
import { ModalType, SignedDocumentType } from '~/types/common';
import { CadesPluginCertificateData } from '~/types/plugin';
import { convertToBase64 } from '~/utils/convert-to-base64';
import { createAlertError } from '~/utils/create-alert-error';
import { downloadSignature } from '~/utils/download-signature';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';
import { getLocalStorageItem } from '~/utils/local-storage';
import { MODAL_VARIANT_FOR_PLUGIN } from '~/utils/plugin/constants';
import { fetchAllCertificatesInSystem } from '~/utils/plugin/fetch-all-certificates-in-system';
import { signAuthRequest } from '~/utils/plugin/sign-auth-request';

export const useSignDocument = () => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm<SignedFormTypes>();
    const [userCertificates, setUserCertificates] = useState<{ value: string; label: string }[]>(
        [],
    );
    const [signedDocument, setSignedDocument] = useState<SignedDocumentType | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isAbortFetchCertificates, setIsAbortFetchCertificates] = useState(false);
    const [isShowResultButton, setIsShowResultButton] = useState(false);
    const [initialCertificate, setInitialCertificate] = useState('');
    const [modal, setModal] = useState<ModalType>({ isError: false, text: '', title: '' });
    const currentFileExist = isNotEmptyArray(useWatch(SignDocumentFields.content, form));
    const isCertificateSelected = useWatch(SignDocumentFields.thumbprint, form);
    const { errorType, loadCadesPlugin, isInstalledEnviroment } = useCheckCadesExtension();
    const isDisabledSignButton =
        !currentFileExist ||
        isLoading ||
        !isCertificateSelected ||
        (currentFileExist && isShowResultButton);
    const isShowForm = !isAbortFetchCertificates && isInstalledEnviroment;
    const isWarning = !isLoading && (isAbortFetchCertificates || !isInstalledEnviroment);
    const abortedFetchingCertificatesText = errorType
        ? MODAL_VARIANT_FOR_PLUGIN[errorType].text
        : SignDocumentText.empty;
    const pluginErrorText = errorType
        ? MODAL_VARIANT_FOR_PLUGIN[errorType].text
        : ModalTextVariant.needExtension;

    const warningText: ReactElement = isAbortFetchCertificates ? (
        <Fragment>
            {abortedFetchingCertificatesText}
            <br />
            {SignDocumentText.reload}
        </Fragment>
    ) : (
        <span>{pluginErrorText}</span>
    );

    const handleFetchCertificatesError = useCallback(
        (error: string) => {
            setIsAbortFetchCertificates(true);
            setUserCertificates([]);
            setIsShowResultButton(false);

            if (error === PluginError.cancel || error === PluginError.cancelInInstall) {
                dispatch(setPopupAlert(createAlertError(error).alert));
            } else {
                setModal({ isError: true, text: ERROR_TEXTS[error] || error });
            }
        },
        [dispatch],
    );

    const handleCheckExtensionError = useCallback(() => {
        setIsLoading(false);
        if (errorType) {
            setModal({
                isError: true,
                text: MODAL_VARIANT_FOR_PLUGIN[errorType].text,
                title: MODAL_VARIANT_FOR_PLUGIN[errorType].title,
            });
        }
    }, [errorType]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const certificates: CadesPluginCertificateData[] | string =
                await fetchAllCertificatesInSystem();

            if (!Array.isArray(certificates)) {
                handleFetchCertificatesError(certificates);

                return;
            }

            const certificateOptions = returnCertificatesOptions(certificates);

            setUserCertificates(certificateOptions);

            const initialThumbprint = getLocalStorageItem(LOCAL_STORAGE.thumbprint);
            const isValueForCertificate = certificateOptions.some(
                ({ value }) => value === initialThumbprint,
            );
            const initialValue =
                isValueForCertificate && initialThumbprint ? initialThumbprint : '';

            setInitialCertificate(initialValue);
            form.setFieldValue(SignDocumentFields.thumbprint, initialValue);
        } catch (_) {
            handleFetchCertificatesError(ERROR_TEXTS.defaultError);
        } finally {
            if (!loadCadesPlugin) setIsLoading(false);
        }
    }, [form, handleFetchCertificatesError, loadCadesPlugin]);

    const checkStatus = useCallback(async () => {
        try {
            if (isInstalledEnviroment) {
                fetchData();
            } else {
                handleCheckExtensionError();
            }
        } catch {
            handleCheckExtensionError();
        }
    }, [fetchData, handleCheckExtensionError, isInstalledEnviroment]);

    const onSignDocument = async () => {
        setIsLoading(true);
        try {
            const { content, thumbprint, signatureType } = await form.validateFields();

            const base64Files = await Promise.all(
                content.map(async ({ originFileObj }) =>
                    originFileObj ? convertToBase64(originFileObj) : null,
                ),
            );

            const fileName = content[0].name;
            const signature = await signAuthRequest(
                base64Files[0] || '',
                thumbprint,
                !!signatureType,
            );

            if (signature.isError) {
                setModal(signature);
            } else {
                setSignedDocument({ fileName, base64File: signature.text });
                setIsShowResultButton(true);
            }
        } catch (_) {
            setModal({ isError: true, text: ERROR_TEXTS.defaultError });
        } finally {
            setIsLoading(false);
        }
    };

    const downloadSignedFile = () => {
        if (signedDocument) downloadSignature(signedDocument);
    };

    const resetFileField = () => form.setFieldValue(SignDocumentFields.content, []);

    const modalCloseHandler = () =>
        setModal((prevValue) => ({ ...prevValue, isError: !prevValue.isError }));

    useEffect(() => {
        checkStatus();
    }, [checkStatus]);

    useEffect(() => {
        if (!currentFileExist) {
            setIsShowResultButton(false);
        }
    }, [currentFileExist]);

    return {
        form,
        isShowResultButton,
        downloadSignedFile,
        resetFileField,
        isShowForm,
        isWarning,
        warningText,
        isLoading,
        userCertificates,
        initialCertificate,
        onSignDocument,
        isDisabledSignButton,
        modal,
        modalCloseHandler,
    };
};
