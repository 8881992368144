import { API } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { interceptor } from '../interceptor';

import { AlertMessage } from '~/constants/alert-message';
import { Tags } from '~/rtk-queries/constants/tags';
import {
    CountriesResponse,
    UserInfoParams,
    UserInfoResponse,
    UserNameResponse,
} from '~/rtk-queries/types/user-info';

export const privateRoomInfo = interceptor
    .enhanceEndpoints({
        addTagTypes: [Tags.USER_NAME],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUserInfo: builder.mutation<UserInfoResponse, UserInfoParams>({
                query: (params) => ({
                    url: API.userInfo,
                    method: 'GET',
                    params,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),

            getRegionInfo: builder.query<CountriesResponse, void>({
                query: () => ({
                    url: API.countries,
                    method: 'GET',
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),

            getUserName: builder.query<UserNameResponse, void>({
                query: () => ({
                    url: API.userName,
                    method: 'GET',
                }),
                providesTags: [Tags.USER_NAME],
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),
        }),
    });

export const { useGetUserInfoMutation, useGetUserNameQuery, useGetRegionInfoQuery } =
    privateRoomInfo;
