import { FC, PropsWithChildren } from 'react';
import { SpinProps } from 'antd';

import { usePreventScroll } from '~/hooks/use-prevent-scroll';
import { CustomSpinner } from '~/shared/custom-spinner';

type WithLoaderProps = {
    isLoading?: boolean;
    className?: string;
} & SpinProps;

export const WithLoader: FC<PropsWithChildren<WithLoaderProps>> = ({
    children,
    isLoading,
    className = '',
    ...spinProps
}) => {
    usePreventScroll(isLoading);

    return isLoading ? <CustomSpinner className={className} {...spinProps} /> : children;
};
