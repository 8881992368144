import { MainInfoPropsType } from '~/components/check-and-sign-certificate/content/check-document/result-block/main-info/main-info';

export const COLUMN_NAME = {
    resultOfChecking: 'Результат проверки',
    sourceDocument: 'Исходный документ',
    additionalInformation: 'Дополнительная информация',
};

export const returnArrayForTable = ({ result, message, fileName }: MainInfoPropsType) => [
    {
        columnName: COLUMN_NAME.resultOfChecking,
        columnResult: result ? 'Подпись действительна' : 'Подпись недействительна',
    },
    {
        columnName: COLUMN_NAME.sourceDocument,
        columnResult: fileName,
    },
    { columnName: COLUMN_NAME.additionalInformation, columnResult: message },
];
