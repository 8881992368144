import { convertDateTime } from '~/components/check-and-sign-certificate/content/check-document/result-block/helpers/convert-date-time';
import { SignerCertificateInfo } from '~/rtk-queries/types/certificate-verification';

export const COLUMN_NAME = {
    subjectName: 'Субъект',
    serialNumber: 'Серийный номер',
    issuerName: 'Издатель',
    thumbprint: 'Отпечаток сертификата',
    expirationDate: 'Срок действия',
    privateKeyExpirationDate: 'Срок действия закрытого ключа',
    revocationReasonDisplayName: 'Причина отзыва',
    certificateRevocationDate: 'Время отзыва',
};

export const returnArrayForTable = (info: SignerCertificateInfo) => {
    const {
        SubjectName,
        SerialNumber,
        IssuerName,
        Thumbprint,
        NotAfterUTC,
        NotBeforeUTC,
        PkNotBeforeUTC,
        PkNotAfterUTC,
        CertificateRevocationDateUtc,
        RevocationReasonDisplayName,
    } = info;
    const formattedInfo = {
        SubjectName,
        SerialNumber,
        IssuerName,
        Thumbprint,
        ExpirationDate: `${convertDateTime(NotBeforeUTC)} - ${convertDateTime(NotAfterUTC)}`,
        PrivateKeyExpirationDate: `${convertDateTime(PkNotBeforeUTC)} - ${convertDateTime(
            PkNotAfterUTC,
        )}`,
        RevocationReasonDisplayName,
        CertificateRevocationDate: CertificateRevocationDateUtc
            ? convertDateTime(CertificateRevocationDateUtc)
            : undefined,
    };

    return [
        {
            columnName: COLUMN_NAME.subjectName,
            columnResult: formattedInfo.SubjectName,
        },
        { columnName: COLUMN_NAME.issuerName, columnResult: formattedInfo.IssuerName },
        { columnName: COLUMN_NAME.serialNumber, columnResult: formattedInfo.SerialNumber },
        { columnName: COLUMN_NAME.expirationDate, columnResult: formattedInfo.ExpirationDate },
        {
            columnName: COLUMN_NAME.privateKeyExpirationDate,
            columnResult: formattedInfo.PrivateKeyExpirationDate,
        },
        { columnName: COLUMN_NAME.thumbprint, columnResult: formattedInfo.Thumbprint },
        {
            columnName: COLUMN_NAME.revocationReasonDisplayName,
            columnResult: formattedInfo.RevocationReasonDisplayName,
        },
        {
            columnName: COLUMN_NAME.certificateRevocationDate,
            columnResult: formattedInfo.CertificateRevocationDate,
        },
    ];
};
