import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';

import styles from '~/shared/result-block-with-collapse/result-block-with-collapse.module.css';

import { COLUMN_NAME } from '~/components/check-and-sign-certificate/content/check-document/result-block/main-info/constants/return-array-for-table';
import {
    TableResult,
    TableResultColumn,
} from '~/components/check-and-sign-certificate/content/types/types';

export const returnColumns = (result: boolean): ColumnsType<TableResultColumn> => [
    {
        key: TableResult.columnName,
        title: TableResult.columnName,
        dataIndex: TableResult.columnName,
        width: '50%',
    },
    {
        key: TableResult.columnResult,
        title: TableResult.columnResult,
        dataIndex: TableResult.columnResult,
        width: '50%',
        render: (value, record, index) => {
            const condition =
                record[TableResult.columnName] === COLUMN_NAME.resultOfChecking ||
                record[TableResult.columnName] === COLUMN_NAME.additionalInformation;

            return (
                <span
                    key={`${value}-${index}`}
                    className={classNames({
                        [styles.textSuccessColor]: condition && result,
                        [styles.textErrorColor]: condition && !result,
                    })}
                >
                    {value}
                </span>
            );
        },
    },
];
