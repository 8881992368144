import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FilterValue } from 'antd/es/table/interface';

import { ERROR_REASONS } from '~/utils/plugin/constants';

export type PartialRecord<Key extends string | number | symbol, Value> = Partial<
    Record<Key, Value>
>;

export enum TableParams {
    sortColumn = 'sortColumn',
    sortOrder = 'sortOrder',
    pageSize = 'pageSize',
    pageNumber = 'pageNumber',
    filter = 'filter',
}

export enum TableFilter {
    title = 'title',
    lastEditDate = 'lastEditDate',
    status = 'status',
}

export type TimeoutIdType = ReturnType<typeof setTimeout>;

export type CommonFilters = Record<string, FilterValue | null>;

export type TableFilters = {
    [TableFilter.title]?: Array<string>;
    [TableFilter.status]?: Array<number>;
    [TableFilter.lastEditDate]?: Array<string>;
};

export type OptionType = {
    value: string | number;
    label: string;
};

export type CommonResponse<T = Record<string, never>> = {
    hasError: boolean;
    message: string | null;
} & T;

export enum Statuses {
    ERROR = 'error',
}

export type AlertStatuses = {
    type: Statuses;
    message: string;
    time?: number;
};
export type AlertError = FetchBaseQueryError & {
    alert: AlertStatuses;
};

export type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

export type ValidateErrorEntity<T> = {
    values: T;
    errorFields: { name: (string | number)[]; errors: string[] }[];
    outOfDate: boolean;
};

export type ModalType = {
    isError: boolean;
    text: string;
    title?: string;
};

export type SignedDocumentType = {
    fileName: string;
    base64File: string;
};

export type ErrorPluginExtensionType = {
    reason: (typeof ERROR_REASONS)[keyof typeof ERROR_REASONS];
};
