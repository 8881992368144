import { useEffect } from 'react';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { UploadFile } from 'antd/es/upload';

import styles from './file-item.module.css';

import { FileExtensionIcon } from '~/shared/file-extenion-icon';
import { IconButton } from '~/shared/icon-button';
import { getFileExtension } from '~/utils/get-file-extension';

type FileItemProps = {
    file: UploadFile;
    fileList: UploadFile[];
    remove: () => void;
    setCurrentFileList: (fileList: UploadFile[]) => void;
    showExtensionIcon?: boolean;
    showRemoveIcon?: boolean;
    isLoading?: boolean;
    prefix?: string;
};

export const FileItem = ({
    file,
    showExtensionIcon = false,
    showRemoveIcon = true,
    remove,
    fileList,
    setCurrentFileList,
    isLoading = false,
    prefix = '',
}: FileItemProps) => {
    const fileExtension = showExtensionIcon ? getFileExtension(file.name) : '';

    useEffect(() => {
        setCurrentFileList(fileList);
    }, [fileList, setCurrentFileList]);

    return (
        <div className={styles.uploadItem}>
            <FileExtensionIcon type={fileExtension} className={styles.fileIcon} />
            <span className={styles.prefix}>{prefix}</span>
            <span className={styles.fileName}>{file.name}</span>

            {showRemoveIcon && (
                <Tooltip title='Удалить' placement='bottomRight' arrow={{ pointAtCenter: true }}>
                    <IconButton
                        className={styles.removeButton}
                        onClick={remove}
                        icon={
                            isLoading ? (
                                <LoadingOutlined className={styles.loadingIcon} />
                            ) : (
                                <DeleteOutlined className={styles.removeIcon} />
                            )
                        }
                        shape='default'
                    />
                </Tooltip>
            )}
        </div>
    );
};
