import { FC, PropsWithChildren } from 'react';

import styles from './index.module.css';

type SectionContainerProps = {
    label: string;
} & PropsWithChildren;

export const SectionContainer: FC<SectionContainerProps> = ({ label, children }) => (
    <div className={styles.container}>
        <div className={styles.applicationTabSubtitle}>{label}</div>
        <div className={styles.applicationTabBlock}>{children}</div>
    </div>
);
