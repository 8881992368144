export type TemplateInfo = {
    folderId: number;
    templateId: number;
    description: string;
};

export const parseTemplateString = (templateString?: string | null) => {
    if (!templateString) return {};
    const parts = templateString.split('-');

    if (parts.length !== 3) {
        return {};
    }

    const folderId = Number(parts[0]);
    const templateId = Number(parts[1]);
    const description = parts[2].trim();

    return {
        folderId,
        templateId,
        description,
    };
};
