export const sortStrings = (a?: string | null, b?: string | null) => {
    if (!a && !b) {
        return 0;
    }
    if (!b) {
        return 1;
    }
    if (!a) {
        return -1;
    }

    return a.localeCompare(b, 'en', { sensitivity: 'base' });
};
