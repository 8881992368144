import { base64ToUint8Array } from './decode-base64-to-uint8-array';

import { SignedDocumentType } from '~/types/common';

export const downloadSignature = ({ base64File, fileName }: SignedDocumentType) => {
    const binaryData = base64ToUint8Array(base64File);
    const blob = new Blob([binaryData], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = url;
    a.download = `${fileName}.sig`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
