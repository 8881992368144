import { OptionType } from '~/types/common';

export enum SignatureSelection {
    unpinnedSignature = 1,
    pinnedSignature = 2,
}

export const SIGNATURE_SELECTION_OPTIONS: OptionType[] = [
    {
        value: SignatureSelection.unpinnedSignature,
        label: 'Откреплённая подпись',
    },
    {
        value: SignatureSelection.pinnedSignature,
        label: 'Присоединённая подпись',
    },
];
