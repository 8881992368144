import { useLayoutEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_PAGE, DEFAULT_SIZE, SORT_ORDERS } from '~/constants/general';
import { TableFilter, TableParams } from '~/types/common';

export const startObj = {
    pageNumber: Number(DEFAULT_PAGE),
    pageSize: Number(DEFAULT_SIZE),
    sortColumn: TableFilter.lastEditDate,
    sortOrder: SORT_ORDERS.descend,
};

export const useHookForPagination = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isMounted = useRef(false);

    const currentTableSize = searchParams.get(TableParams.pageSize) || DEFAULT_SIZE;
    const currentTablePage = searchParams.get(TableParams.pageNumber) || DEFAULT_PAGE;
    const currentSortColumn = searchParams.get(TableParams.sortColumn);
    const currentSortOrder = searchParams.get(TableParams.sortOrder);

    const paramObj = useMemo(() => Object.fromEntries(searchParams), [searchParams]);
    const isEmptyParamObj = useMemo(() => Object.keys(paramObj).length === 0, [paramObj]);

    const searchParamsObj = useMemo(
        () => ({
            pageNumber: Number(paramObj.pageNumber),
            pageSize: Number(paramObj.pageSize),
            sortColumn: paramObj.sortColumn,
            sortOrder: paramObj.sortOrder,
        }),
        [paramObj.pageNumber, paramObj.pageSize, paramObj.sortColumn, paramObj.sortOrder],
    );

    useLayoutEffect(() => {
        if (!currentTableSize || !isMounted.current || !currentTablePage) {
            searchParams.set(TableParams.pageSize, String(currentTableSize));
            searchParams.set(TableParams.pageNumber, String(currentTablePage));
            searchParams.set(TableParams.sortColumn, currentSortColumn || TableFilter.lastEditDate);
            searchParams.set(TableParams.sortOrder, currentSortOrder || SORT_ORDERS.descend);
            setSearchParams(searchParams);
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, [currentTablePage, currentTableSize, searchParams, setSearchParams, isMounted.current]);

    return {
        isEmptyParamObj,
        searchParamsObj,
        currentTableSize,
        currentTablePage,
        currentSortColumn,
        currentSortOrder,
        isMounted,
    };
};
