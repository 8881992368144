import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import classnames from 'classnames';

import { SelectCertificate } from '../select-certificate';

import styles from './certificates-cards.module.css';

import { ENTER } from '~/constants/enter';
import { EnvelopeIcon } from '~/icons/envelope-icon';
import { TextLinkEnd } from '~/shared/text-link-end';
import { CadesPluginCertificateData } from '~/types/plugin';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';

type CertificatesCardsProps = {
    userCertificates: CadesPluginCertificateData[] | null;
    isDisabled: boolean;
    authorizationByCertificateHandler?: (id: string) => Promise<void>;
};

export const CertificatesCards: FC<CertificatesCardsProps> = ({
    authorizationByCertificateHandler,
    isDisabled,
    userCertificates,
}) => (
    <React.Fragment>
        {isNotEmptyArray(userCertificates) && (
            <TextLinkEnd
                screenTitle={ENTER.selectCertificate}
                className={styles.chooseCertificateText}
            />
        )}
        <div
            className={classnames(styles.certificateContainer, {
                [styles.notArray]: !Array.isArray(userCertificates),
            })}
        >
            {isNotEmptyArray(userCertificates) ? (
                userCertificates.map((certificate) => (
                    <SelectCertificate
                        key={certificate.containerName}
                        thumbprint={certificate.signByThumbprint}
                        authorizationByCertificateHandler={authorizationByCertificateHandler}
                        certificate={certificate}
                        validToDate={certificate.validToDate}
                        isDisabled={isDisabled}
                    />
                ))
            ) : (
                // TO DO Есть встроенный компонент ant design Empty
                <Space className={styles.emptyBlock}>
                    <Typography.Text className={styles.text}>
                        {ENTER.selectCertificate.empty}
                    </Typography.Text>
                    <EnvelopeIcon />
                </Space>
            )}
        </div>
    </React.Fragment>
);
