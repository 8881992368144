export enum FormBlockLabel {
    citizenshipId = 'Гражданство',
    gender = 'Пол',
    birthDate = 'День рождения',
    mobile = 'Мобильный',
    region = 'Страна/регион',
    snils = 'СНИЛС',
}

export enum FormPlaceholders {
    CertificateTemplateSelect = 'Назначение сертификата',
}

export const NotificationTextPartOne = {
    text: 'При обнаружении ошибок в данных измените их в',
    linkText: 'Госуслугах',
    link: 'https://www.gosuslugi.ru/',
};

export const NotificationTextPartTwo = 'и повторно авторизуйтесь через сервис Госуслуг';

export const DRAFT_MODE = 'draftMode';

export const FormButtonLabel = {
    removeDraft: 'удалить черновик',
};
