import { AlertStatuses } from '~/types/common';

type AlertInfoType = {
    alert: AlertStatuses;
};

export const isAlertWithMessage = (info: unknown): info is AlertInfoType => {
    if (typeof info !== 'object' || info === null) {
        return false;
    }
    if (
        !('alert' in info) ||
        typeof info.alert !== 'object' ||
        info.alert === null ||
        !('message' in info.alert)
    ) {
        return false;
    }

    return true;
};
