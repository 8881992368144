import { items } from './constants/items';

import styles from './private-room.module.css';

import { ContactUs } from '~/components/contact-us';
import { ROUTES } from '~/constants/routes';
import { CustomTabs } from '~/shared/custom-tabs/custom-tabs';

export const PrivateRoom = () => {
    const startingTabKey = items?.at(1)?.key ?? '';

    return (
        <div className={styles.privateRoomContainer}>
            <CustomTabs
                items={items}
                basePath={ROUTES.privateRoom}
                startingTabKey={startingTabKey}
            />
            <ContactUs />
        </div>
    );
};
