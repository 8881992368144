import { removeSpaces } from '../remove-spaces';

import { filterCommontForm } from './filter-common-form';
import { filterDocumentForm } from './filter-document-form';

import { IdentityDocumentType } from '~/constants/application-tab/certificate-form';
import { DocumentSectionInput } from '~/constants/application-tab/validation';
import { CreateDocument, CreateUserInfo } from '~/rtk-queries/types/certificates';
import { IdentityDocument, UserInfo, UserInfoDataValue } from '~/rtk-queries/types/user-info';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { PartialRecord } from '~/types/common';
import { formatStringDate } from '~/utils/format-string-date';
import { removeNonAlphanumeric } from '~/utils/remove-non-alphanumeric';

type FormKeys = keyof Partial<CertificateForm>;

export const prepareCreateRequestBody = (
    formValues: CertificateForm,
    userInfo?: UserInfo,
): Omit<CreateUserInfo, 'id'> => {
    const {
        birthDate,
        gender,
        mobile,
        citizenshipId,
        countryId,
        document: formDocument,
        ...rest
    } = formValues;

    const userInfoUnwrapped: PartialRecord<FormKeys, UserInfoDataValue> = {
        ...userInfo?.address,
        ...userInfo?.main,
        ...userInfo?.other,
    };

    const { id, type, series, number, issueDate, issuedBy, issueId, expiryDate } = formDocument;

    const createDocument: Omit<CreateDocument, 'id' | 'type'> = {
        series: removeSpaces(series),
        issueId: removeSpaces(issueId),
        issueDate: formatStringDate(issueDate),
        expiryDate: formatStringDate(expiryDate),
        number,
        issuedBy,
    };

    const createCommonInfo: Omit<CreateUserInfo, 'document' | 'id'> = {
        ...rest,
        mobile: removeNonAlphanumeric(mobile),
        birthDate: formatStringDate(birthDate),
        gender: Number(gender) || 0,
        citizenshipId: Number(citizenshipId) || 0,
        countryId: Number(countryId) || 0,
    };

    const dataDocument = userInfo?.documents.find(
        ({ type: dataType }) => dataType?.value && type === Number(dataType.value),
    );

    const { id: _id, type: _type, ...restDataDocument } = dataDocument ?? ({} as IdentityDocument);

    const filteredInfo = filterCommontForm(createCommonInfo, userInfoUnwrapped);
    const filteredDocument = filterDocumentForm(
        createDocument,
        restDataDocument,
        DocumentSectionInput[type ?? IdentityDocumentType.Empty],
    );

    const document = { id, ...filteredDocument, type: type ?? IdentityDocumentType.Empty };

    if (!document.id) {
        delete document.id;
    }

    return {
        ...filteredInfo,
        document,
    };
};
