import { useNavigate } from 'react-router-dom';
import { FileTextOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';

import { useApplicationModal } from './use-application-modal';

import { BASE_PARAMS } from '~/components/certificates-table/constants/general';
import { AccountTab } from '~/constants/account-tab';
import { BUTTON_TEXT } from '~/constants/button';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ModalTextVariant } from '~/constants/modal';
import { ROUTES } from '~/constants/routes';
import { useCreateCertificateRequestMutation } from '~/rtk-queries/endpoints/certificates-list';
import { UserInfoResponse } from '~/rtk-queries/types/user-info';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { ValidateErrorEntity } from '~/types/common';
import { createRequestSuccessText } from '~/utils/application-tab/create-success-text';
import { prepareCreateRequestBody } from '~/utils/application-tab/prepare-create-request-body';
import { resetCertificateForm } from '~/utils/application-tab/reset-certificate-form';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';
import { getLocalStorageItem } from '~/utils/local-storage';

export const useCreateApplication = (
    form: FormInstance,
    { folderId, templateId }: { folderId: number; templateId: number },
    userInfoData: UserInfoResponse | undefined,
    deleteDraft: () => Promise<void>,
    templateName: string,
) => {
    const storedDraftData = getLocalStorageItem(LOCAL_STORAGE.draftData);

    const navigate = useNavigate();
    const { openApplicationModal } = useApplicationModal();

    const [postCreateCertificateRequest, { isLoading: isLoadingCreateCertificate }] =
        useCreateCertificateRequestMutation();

    const createApplication = async () => {
        try {
            const formValues = await form.validateFields();

            const newFormValues = prepareCreateRequestBody(formValues, userInfoData?.userInfo);

            const value = {
                folderId,
                templateId,
                userInfoTemp: { ...newFormValues, id: userInfoData?.userInfo.id ?? 0 },
            };

            await postCreateCertificateRequest(value).unwrap();

            if (storedDraftData) deleteDraft();

            resetCertificateForm(form);
            openApplicationModal({
                title: ModalTextVariant.createRequest,
                content: createRequestSuccessText(templateName),
                icon: <FileTextOutlined />,
                okText: BUTTON_TEXT.ok.toLocaleUpperCase(),
            });

            navigate(`${ROUTES.privateRoom}/${AccountTab.certificateTab}${BASE_PARAMS}`);
        } catch (error) {
            const errorObj = error as ValidateErrorEntity<CertificateForm>;

            if (
                isNotEmptyArray(errorObj?.errorFields) &&
                isNotEmptyArray(errorObj.errorFields[0].name)
            ) {
                form.scrollToField(errorObj.errorFields[0].name[0], {
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    };

    return {
        createApplication,
        isLoadingCreateCertificate,
    };
};
