import React from 'react';
import { Card, Table } from 'antd';

import { returnArrayForTable } from '~/components/check-and-sign-certificate/content/shared/additional-result/constants/return-array-for-table';
import { returnColumns } from '~/components/check-and-sign-certificate/content/shared/additional-result/constants/return-columns';

export type AdditionalResultPropsType = {
    errors: Array<string>;
    result: boolean;
};

export const AdditionalResult: React.FC<AdditionalResultPropsType> = ({ errors, result }) => (
    <Card>
        <Table
            dataSource={returnArrayForTable({ result, errors })}
            columns={returnColumns(result)}
            pagination={false}
            showHeader={false}
            scroll={{ x: 400 }}
        />
    </Card>
);
