import { useEffect, useRef } from 'react';

export const useCompareValue = <T>(value: T): { hasChanged: boolean; previousValue: T | null } => {
    const ref = useRef<T | null>(null);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    const hasChanged = ref.current !== value;

    return { hasChanged, previousValue: ref.current };
};
