import { useEffect } from 'react';

export const usePreventScroll = (isPreventing = false) => {
    useEffect(() => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

        const disableScroll = () => {
            if (isPreventing) {
                window.scrollTo(scrollLeft, scrollTop);
            }
        };

        document.addEventListener('scroll', disableScroll);

        return () => {
            document.removeEventListener('scroll', disableScroll);
        };
    }, [isPreventing]);
};
