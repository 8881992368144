import { SignedDocumentType } from '~/types/common';

export const downloadCertificate = ({ base64File, fileName }: SignedDocumentType) => {
    const blob = new Blob(
        [
            `-----BEGIN CERTIFICATE-----
${base64File}
-----END CERTIFICATE-----`,
        ],
        { type: 'application/octet-stream' },
    );
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = url;
    a.download = `${fileName}.cer`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
