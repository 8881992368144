import { FC, ReactNode } from 'react';
import { Space, Typography } from 'antd';

import styles from './custom-modal-title.module.css';

type CustomModalTitleProps = {
    title: ReactNode;
    icon?: ReactNode;
    imageSrc?: string;
};

export const CustomModalTitle: FC<CustomModalTitleProps> = ({ imageSrc, title, icon }) => (
    <Space direction='horizontal' align='center' className={styles.modalContentTitle}>
        {!!icon && icon}
        {!!imageSrc && <img src={imageSrc} alt='unlocks-icon' />}
        <Typography.Title level={3}>{title}</Typography.Title>
    </Space>
);
