import { createArrayLoader, createEnvironment } from 'twing';

import { PrintTemplateType, statusesRevokeInProgress } from '~/constants/certificates';
import { LifeStatus } from '~/constants/certificates-table';
import { REVOKE_REASON_PRINT, RevReasonPKI } from '~/constants/revoke-certificate';
import { useLazyGetNamePolicyQuery } from '~/rtk-queries/endpoints/name-policy';
import {
    useLazyGetPKICertificateQuery,
    useLazyGetPKICertRequestQuery,
    useLazyGetPKIRevokeQuery,
    useLazyGetPKIUserQuery,
} from '~/rtk-queries/endpoints/pki';
import {
    useLazyGetInitForPrintQuery,
    useLazyGetPrintedTemplateQuery,
} from '~/rtk-queries/endpoints/template';
import { Context } from '~/rtk-queries/types/pki';
import { InitPrint } from '~/rtk-queries/types/template';
import {
    isCertificateApplication,
    isCertificateInstance,
    isCertificatePreApplication,
    isCertificateRevocation,
} from '~/utils/is-certificate-variant';
import { Certificate } from '~/utils/transform-certificates-data/type';

export const usePrintDocument = () => {
    const [getPrintedTemplate, { isFetching: isFetchingTemplate }] =
        useLazyGetPrintedTemplateQuery();
    const [getPrintedTemplateHeader, { isFetching: isFetchingHeader }] =
        useLazyGetPrintedTemplateQuery();
    const [getInitForPrint, { isFetching: isFetchingInit }] = useLazyGetInitForPrintQuery();
    const [getPKICertRequest, { isFetching: isFetchingRequest }] = useLazyGetPKICertRequestQuery();
    const [getPKICertificate, { isFetching: isFetchingCertificate }] =
        useLazyGetPKICertificateQuery();
    const [getPKIUser, { isFetching: isFetchingUser }] = useLazyGetPKIUserQuery();
    const [getNameAttributes, { isFetching: isFetchingOidNames }] = useLazyGetNamePolicyQuery();
    const [getRevokedCertificateData, { isFetching: isFetchingRevokedCertificateData }] =
        useLazyGetPKIRevokeQuery();

    const isFetchingPrintData =
        isFetchingHeader ||
        isFetchingInit ||
        isFetchingOidNames ||
        isFetchingRequest ||
        isFetchingTemplate ||
        isFetchingUser ||
        isFetchingCertificate ||
        isFetchingRevokedCertificateData;

    const handlePreApplicationStatus = async (
        certificate: Certificate,
        context: Context,
    ): Promise<Context> => {
        if (!isCertificatePreApplication(certificate)) return context;
        const { data } = await getPKIUser(certificate.pkiUserId);

        return { ...context, user: data?.pkiUser };
    };

    const handleApplicationStatus = async (
        certificate: Certificate,
        context: Context,
    ): Promise<Context> => {
        if (!isCertificateApplication(certificate)) return context;
        const { data: certRequest } = await getPKICertRequest(certificate.certRequestId);

        if (certRequest) {
            const { userId } = certRequest.pkiCertRequest;
            const { data: userData } = await getPKIUser(userId);

            return {
                ...context,
                user: userData?.pkiUser,
                certRequest: certRequest.pkiCertRequest,
            };
        }

        return context;
    };

    const handleCertificateStatus = async (
        certificate: Certificate,
        context: Context,
    ): Promise<Context> => {
        if (!isCertificateInstance(certificate)) return context;

        const { data: certificateData } = await getPKICertificate(certificate.certificateId);

        if (!certificateData) return context;

        const { userId } = certificateData.pkiCertificate;
        const { data: userData } = await getPKIUser(userId);

        const revReason =
            REVOKE_REASON_PRINT[certificateData.pkiCertificate.revocationReason as RevReasonPKI] ??
            REVOKE_REASON_PRINT[RevReasonPKI.Unspecified];

        return {
            ...context,
            user: userData?.pkiUser,
            certificate: certificateData.pkiCertificate,
            revReason,
            // уточнить про revoke
        };
    };

    const handleCertificateStatusRevocation = async (
        certificate: Certificate,
        context: Context,
    ): Promise<Context> => {
        if (!isCertificateRevocation(certificate)) return context;

        const { status, revokeRequestId } = certificate;

        if (status && statusesRevokeInProgress.includes(status.statusCode) && revokeRequestId) {
            const { data: revocationData } = await getRevokedCertificateData(revokeRequestId);

            if (!revocationData?.certificateId) return context;

            const { userId, certificateId, revocationReason } = revocationData;

            const { data: certificateData } = await getPKICertificate(certificateId);
            const { data: userData } = await getPKIUser(userId);
            const revReason =
                REVOKE_REASON_PRINT[revocationReason as RevReasonPKI] ??
                REVOKE_REASON_PRINT[RevReasonPKI.Unspecified];

            return {
                ...context,
                user: userData?.pkiUser,
                certificate: certificateData?.pkiCertificate,
                revReason,
            };
        }

        return context;
    };

    const lifeStatusActions: Record<
        LifeStatus,
        (rowData: Certificate, context: Context) => Promise<Context>
    > = {
        [LifeStatus.preApplication]: handlePreApplicationStatus,
        [LifeStatus.application]: handleApplicationStatus,
        [LifeStatus.certificate]: handleCertificateStatus,
        [LifeStatus.revocation]: handleCertificateStatusRevocation,
    };

    const createContext = async (
        certificate: Certificate,
        initForPrint?: InitPrint,
        nameAttributes?: Record<string, string>,
    ): Promise<Context> => {
        const initialContext: Context = {
            dictionary: initForPrint?.dictionary,
            timeZone: initForPrint?.config.TIME_ZONE,
            oidNames: nameAttributes,
        };

        const { lifeStatus } = certificate;

        try {
            if (certificate && lifeStatusActions[lifeStatus]) {
                return await lifeStatusActions[lifeStatus](certificate, initialContext);
            }
        } catch (error) {
            console.error('Error createContext for print:', error);
        }

        return initialContext;
    };

    const onPrintClick = async (templateId: PrintTemplateType, certificate: Certificate) => {
        try {
            const [
                { data: template },
                { data: header },
                { data: nameAttributes },
                { data: initForPrint },
            ] = await Promise.all([
                getPrintedTemplate(templateId),
                getPrintedTemplateHeader(PrintTemplateType.Header),
                getNameAttributes(),
                getInitForPrint(),
            ]);

            const context = await createContext(certificate, initForPrint, nameAttributes);

            const loader = createArrayLoader({
                'index.twig': template as string,
                tplHeader: header as string,
            });
            const environment = createEnvironment(loader);

            environment
                .render('index.twig', context)
                .then((output) => {
                    const newWindow = window.open('', '_blank');

                    if (!newWindow) return;

                    newWindow.document.write(output);

                    newWindow.document.close();
                    setTimeout(() => {
                        newWindow.print();
                    }, 500);
                })
                .catch((error) => {
                    console.error('Error rendering template:', error);
                });
        } catch (e) {
            console.error('Error rendering:', e);
        }
    };

    return {
        onPrintClick,
        isFetchingPrintData,
    };
};
