import { FC, PropsWithChildren } from 'react';
import { Button, ButtonProps } from 'antd';

import styles from './custom-button.module.css';

import { ButtonType } from '~/constants/button';

type CustomButtonProps = ButtonProps & {
    className?: string;
};

export const CustomButton: FC<PropsWithChildren<CustomButtonProps>> = ({
    className = '',
    children,
    ...btnProps
}) => (
    <Button htmlType={ButtonType.button} className={styles[className]} {...btnProps}>
        {children}
    </Button>
);
