import { TabsProps } from 'antd';

import { Archive } from '~/components/archive';
import { CertificatesTable } from '~/components/certificates-table';
import { CheckAndSignCertificate } from '~/components/check-and-sign-certificate';
import { MchdTab } from '~/components/mchd-tab/mchd-tab';
import { AccountTab, AccountTabLabel } from '~/constants/account-tab';
import { ApplicationTab } from '~/pages/application-tab/application-tab';

export const items: TabsProps['items'] = [
    {
        key: AccountTab.applicationTab,
        label: AccountTabLabel.applicationTab,
        children: <ApplicationTab />,
        destroyInactiveTabPane: true,
    },
    {
        key: AccountTab.certificateTab,
        label: AccountTabLabel.certificateTab,
        children: <CertificatesTable />,
    },
    {
        key: AccountTab.checkCertificateTab,
        label: AccountTabLabel.checkCertificateTab,
        children: <CheckAndSignCertificate />,
    },
    {
        key: AccountTab.mchdTab,
        label: AccountTabLabel.mchdTab,
        children: <MchdTab />,
    },
    {
        key: AccountTab.archiveTab,
        label: AccountTabLabel.archiveTab,
        children: <Archive />,
    },
];
