export const STEP_PARAMS = 'stepName';

export const ROUTES = {
    startPage: '/',
    stepsRootPage: '/steps',
    stepPage: `:${STEP_PARAMS}`,
    esiaPage: 'esia',
    privateRoom: '/private-room',
    notFound: '/not-found',
    applicationTab: '/applicationTab',
};
