import { defer, Params } from 'react-router-dom';

import { STEP_PARAMS } from '~/constants/routes';
import { stepName } from '~/constants/step-name';
import { store } from '~/store';
import { setIsLoading } from '~/store/common/common-slice';
import { checkCryptoInstalled } from '~/utils/plugin/check-crypto-installed';

export const loaderCryptoProPlugin = ({ params }: { params: Params<typeof STEP_PARAMS> }) => {
    const currentStep = params.stepName;

    if (currentStep === stepName.one) {
        return defer({ isPluginInstalled: Promise.resolve(true) });
    }

    const checkPlugin = async () => {
        try {
            store.dispatch(setIsLoading(true));

            const cryptoProInstalled = await checkCryptoInstalled();

            return Boolean(cryptoProInstalled);
        } catch (err) {
            console.error(err);
            store.dispatch(setIsLoading(false));

            return false;
        }
    };

    return defer({ isPluginInstalled: checkPlugin() });
};
