import { CadesPluginCertificateData, CadesPluginCertObj } from '../../types/plugin';

export const getCertificateFields = async (certObj: CadesPluginCertObj) => {
    const { enrolljs } = window;

    try {
        const [
            validFromDate,
            validToDate,
            serialNumber,
            issuerName,
            signByThumbprint,
            PrivateKeyPromise,
            subject,
        ] = await Promise.all([
            certObj.ValidFromDate,
            certObj.ValidToDate,
            certObj.SerialNumber,
            certObj.IssuerName,
            certObj.Thumbprint,
            certObj.PrivateKey,
            certObj.SubjectName, //  не ясно, нужен ли тот что хранится в сертификате на компе или забираем с бэка
        ]);

        const {
            ProviderName,
            ProviderType,
            KeySpec,
            ContainerName,
            UniqueContainerName,
            PrivateKey,
        } = PrivateKeyPromise;
        const [provName, provType, keySpec, containerName] = await Promise.all([
            ProviderName,
            ProviderType,
            KeySpec,
            ContainerName,
            UniqueContainerName,
            PrivateKey,
        ]);
        const hashAlgorithm = await enrolljs.getHashAlgorithmListFromCsp(provName); // массив из двух

        return {
            provName,
            provType,
            keySpec,
            keySize: 512, // тоже можно вернуть из промиса
            containerName,
            subject,
            signByThumbprint,
            hashAlgorithm: hashAlgorithm[0].oid, // возможно не тот, и нужно будет поменять
            validFromDate,
            validToDate,
            serialNumber,
            issuerName,
        } as CadesPluginCertificateData;
    } catch (_: unknown) {
        return null;
    }
};
