export const checkDraftNullishString = (initial?: string, draft?: string | number) => {
    if (typeof draft === 'undefined') {
        return initial;
    }

    if (draft === '' || draft === 0) {
        return '';
    }

    return String(draft);
};
