import { ReactElement } from 'react';

export function getChild(children: ReactElement): ReactElement {
    if ('children' in children.props) {
        if (Array.isArray(children.props.children)) {
            return children.props.children.find((el: ReactElement) => el) || children;
        }

        return children;
    }

    return children;
}
