import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { useAppSelector } from '~/hooks';
import { useRefreshTokenMutation } from '~/rtk-queries/endpoints/authentication';
import { accessTokenSelector } from '~/store/auth/auth-slice';
import { getLocalStorageItem } from '~/utils/local-storage';

export const useGetAccessToken = (setIsLoading?: Dispatch<SetStateAction<boolean>>) => {
    const accessToken = useAppSelector(accessTokenSelector);
    const sessionId = Number(getLocalStorageItem(LOCAL_STORAGE.sessionId));
    const refreshToken = getLocalStorageItem(LOCAL_STORAGE.refreshToken);

    const [refresh, { isLoading: isRefreshTokenLoading, isError: isErrorRefreshToken }] =
        useRefreshTokenMutation();

    const getAccessToken = useCallback(() => {
        const needRefresh =
            !accessToken && refreshToken && !isErrorRefreshToken && !isRefreshTokenLoading;

        if (needRefresh) {
            refresh({ refreshToken, sessionId });
            if (setIsLoading) setIsLoading(true);

            return;
        }

        if (setIsLoading) setIsLoading(isRefreshTokenLoading);
    }, [
        accessToken,
        isErrorRefreshToken,
        isRefreshTokenLoading,
        refresh,
        refreshToken,
        sessionId,
        setIsLoading,
    ]);

    useEffect(() => {
        getAccessToken();
    }, [getAccessToken]);
};
