import { useCallback } from 'react';
import { Modal, ModalFuncProps, Typography } from 'antd';

import styles from './index.module.css';

import { CustomButton } from '~/shared/custom-button';

export const useApplicationModal = () => {
    const openApplicationModal = useCallback(
        ({ title, content, icon, onOk, okText }: ModalFuncProps) => {
            const modal = Modal.success({
                title: (
                    <div className={styles.modalTitle}>
                        {icon}
                        <Typography.Title className={styles.modalText} level={4}>
                            {title}
                        </Typography.Title>
                    </div>
                ),
                centered: true,
                content: (
                    <Typography.Text className={styles.contentText}>{content}</Typography.Text>
                ),
                icon: null,
                maskClosable: true,
                width: 520,
                closable: true,
                footer: (
                    <div className={styles.modaFooter}>
                        <CustomButton
                            className='enterPageButtonYes'
                            style={{ margin: 0 }}
                            onClick={() => {
                                onOk?.();
                                modal.destroy();
                            }}
                        >
                            {okText}
                        </CustomButton>
                    </div>
                ),
            });
        },
        [],
    );

    return { openApplicationModal };
};
