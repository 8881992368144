import { Rule } from 'antd/es/form';

import { ValidationRules } from '~/constants/general';
import { RulesSetup } from '~/types/application-tab/certificate-form';

export const createValidationRules = ({ maxLength, minLength, regex, required }: RulesSetup) => {
    const rules: Rule[] = [];

    if (required) {
        rules.push(ValidationRules.required);
    }

    if (regex) {
        rules.push({
            pattern: new RegExp(regex),
            message: 'Не соответствует формату',
        });
    }

    if (minLength) {
        rules.push({
            min: minLength,
            message: `Минимальная длина поля ${minLength} символов`,
        });
    }

    if (maxLength) {
        rules.push({
            max: maxLength,
            message: `Максимальная длина поля ${maxLength} символов`,
        });
    }

    return rules;
};
