import { Collapse, CollapseProps, List } from 'antd';

import { DetailsItem } from '../details-item';

import styles from './details-section.module.css';

import { EnricherData } from '~/components/view-answer-modal/modal-type';

export const DetailsSection = ({ enricherData }: { enricherData: EnricherData[] }) => (
    <List
        dataSource={enricherData}
        renderItem={({ enricher, status, errorMessage }) => {
            const items: CollapseProps['items'] = [
                {
                    key: '1',
                    label: <DetailsItem enricher={enricher} status={status} />,
                    children: errorMessage,
                },
            ];

            return (
                <List.Item
                    style={{ padding: errorMessage ? '0' : '12px 16px', gap: 'var(--gap-m)' }}
                >
                    {errorMessage ? (
                        <Collapse
                            items={items}
                            size='small'
                            destroyInactivePanel={true}
                            ghost={true}
                            className={styles.modalCollapse}
                        />
                    ) : (
                        <DetailsItem enricher={enricher} status={status} />
                    )}
                </List.Item>
            );
        }}
    />
);
