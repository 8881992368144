import { ReactNode } from 'react';

import { CheckCertificate } from '../check-certificate';
import { CheckDocument } from '../check-document';
import { SignDocument } from '../sign-document';

import { KEYS_FOR_TAB } from '~/components/check-and-sign-certificate/types/keys-for-tab';

export const CONTENT: Record<KEYS_FOR_TAB, ReactNode> = {
    [KEYS_FOR_TAB.checkCertificate]: <CheckCertificate />,
    [KEYS_FOR_TAB.checkDocument]: <CheckDocument />,
    [KEYS_FOR_TAB.signDocument]: <SignDocument />,
};
