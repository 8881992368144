import React, { FC, MouseEventHandler } from 'react';
import { useParams } from 'react-router-dom';
import { Space } from 'antd';

import { AnimatedEllipsis } from '../animated-ellipsis';
import { CustomButton } from '../custom-button';
import { CustomSpinner } from '../custom-spinner';
import { TextLinkEnd } from '../text-link-end';

import { CertificatesBlock } from './components/certificates-block';
import { LinkServices } from './components/link-services';
import { getDataForRender } from './utils/get-data-for-render';

import styles from './enter-screen.module.css';

import { ButtonType } from '~/constants/button';
import { CRYPTO_PLUGIN_CHECKED_TEXT } from '~/constants/plugin';
import { stepName } from '~/constants/step-name';
import { StepParams } from '~/types/router';
import { FrontConfigType } from '~/utils/get-front-config/type';

type EnterScreenProps = {
    onClickButtonBottom: MouseEventHandler<HTMLElement>;
    isLoadingPlugin: boolean;
    linkEsia?: string | null;
    linkInstall?: string;
    onClickButtonTop?: MouseEventHandler<HTMLElement>;
    frontConfig?: FrontConfigType;
};

export const EnterScreen: FC<EnterScreenProps> = ({
    isLoadingPlugin,
    linkEsia,
    linkInstall,
    onClickButtonTop,
    onClickButtonBottom,
    frontConfig,
}) => {
    const { stepName: currentStep } = useParams<StepParams>();

    const { buttonTypeTop, titleTop, screenTitle, screenSubTitle } = getDataForRender(
        currentStep,
        frontConfig,
    );

    const isFinalScreen = currentStep === stepName.five;

    const isDownloadPluginButton =
        buttonTypeTop !== ButtonType.link &&
        buttonTypeTop &&
        titleTop &&
        onClickButtonTop &&
        !linkEsia &&
        !linkInstall;

    return (
        <Space
            direction='vertical'
            align='center'
            size={24}
            className={styles.enterScreenContainer}
        >
            {isLoadingPlugin && currentStep !== stepName.one ? (
                <CustomSpinner
                    tip={<AnimatedEllipsis title={CRYPTO_PLUGIN_CHECKED_TEXT} />}
                    className={styles.loader}
                    size='large'
                />
            ) : (
                <React.Fragment>
                    {screenTitle && !isFinalScreen && (
                        <TextLinkEnd
                            linkInstall={linkInstall}
                            screenTitle={screenTitle}
                            className={styles.textWithLink}
                        />
                    )}
                    {screenSubTitle && (
                        <TextLinkEnd screenTitle={screenSubTitle} className={styles.textWithLink} />
                    )}
                    {isDownloadPluginButton && (
                        <CustomButton
                            type='primary'
                            htmlType={buttonTypeTop ?? ButtonType.button}
                            className='enterPageButton'
                            onClick={onClickButtonTop}
                        >
                            {titleTop}
                        </CustomButton>
                    )}
                    <LinkServices title={titleTop} linkEsia={linkEsia} linkInstall={linkInstall} />
                </React.Fragment>
            )}

            <CertificatesBlock
                isLoadingPlugin={isLoadingPlugin}
                onClickButtonBottom={onClickButtonBottom}
            />
        </Space>
    );
};
