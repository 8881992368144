import { isNotEmptyArray } from './is-not-empty-array';

import { Template } from '~/rtk-queries/types/template';
import { OptionType } from '~/types/common';

export const getTemplateOptions = (templates?: Template[]): OptionType[] => {
    if (!isNotEmptyArray(templates)) {
        return [];
    }

    return templates?.map(({ id, folderId, description, displayName }) => ({
        value: `${folderId}-${id}-${description}`,
        label: displayName,
    }));
};
