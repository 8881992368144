import { useEffect, useState } from 'react';

import { CadesErrorType } from '~/types/plugin';
import { FrontConfigType } from '~/utils/get-front-config/type';
import { checkCadesExtension } from '~/utils/plugin/check-cades-extension';

export const useCheckCadesExtension = (frontConfig?: FrontConfigType) => {
    const [cadesStatus, setCadesStatus] = useState(true);
    const [extentionStatus, setExtentionStatus] = useState(true);
    const [cspStatus, setCspStatus] = useState(true);
    const [browserLink, setBrowserLink] = useState('');
    const [platformLink, setPlatformLink] = useState('');
    const [cspLink, setCspLink] = useState('');
    const [loadCadesPlugin, setLoadCadesPlugin] = useState(true);
    const [errorType, setErrorType] = useState<CadesErrorType | null>(null);
    const isInstalledEnviroment = cadesStatus && cspStatus && extentionStatus;

    useEffect(() => {
        checkCadesExtension({
            setCadesStatus,
            setExtentionStatus,
            setCspStatus,
            setBrowserLink,
            setPlatformLink,
            setCspLink,
            setErrorType,
            setLoadCadesPlugin,
            frontConfig,
        });
    }, [frontConfig]);

    return {
        cspLink,
        cadesStatus,
        browserLink,
        platformLink,
        extentionStatus,
        cspStatus,
        isInstalledEnviroment,
        errorType,
        loadCadesPlugin,
    };
};
