import { changeDateFormat } from './change-date-format';

import { CadesPluginCertificateData } from '~/types/plugin';

type СonvertSubjextToDataCertificate = {
    certificate?: CadesPluginCertificateData;
    validToDate?: string;
};
export const convertSubjectToDataCertificate = ({
    certificate,
    validToDate,
}: СonvertSubjextToDataCertificate) => {
    const keyEmailNumber = '1.2.840.113549.1.9.1=';
    const keyEmailString = 'E=';

    const keyNameNumber = '2.5.4.42=';
    const keyNameString = 'G=';

    const keySurnameNumber = '2.5.4.4=';
    const keySurnameString = 'SN=';

    const keyFullNameString = 'CN=';

    const keyIssueByString = 'CN=';

    const emailKeyNumber = certificate?.subject
        ?.split(', ')
        .find((item: string) => item.includes(keyEmailNumber))
        ?.replace(keyEmailNumber, '');
    const emailKeyString = certificate?.subject
        ?.split(', ')
        .find((item: string) => item.includes(keyEmailString))
        ?.replace(keyEmailString, '');

    const surnameKeyNumber = certificate?.subject
        ?.split(', ')
        .find((item: string) => item.includes(keySurnameNumber))
        ?.replace(keySurnameNumber, '');
    const surnameKeyString = certificate?.subject
        ?.split(', ')
        .find((item: string) => item.includes(keySurnameString))
        ?.replace(keySurnameString, '');

    const nameKeyNumber = certificate?.subject
        ?.split(', ')
        .find((item: string) => item.includes(keyNameNumber))
        ?.replace(keyNameNumber, '');
    const nameKeyString = certificate?.subject
        ?.split(', ')
        .find((item: string) => item.includes(keyNameString))
        ?.replace(keyNameString, '');

    const fullNameKeyString = certificate?.subject
        ?.split(', ')
        .find((item: string) => item.includes(keyFullNameString))
        ?.replace(keyFullNameString, '');

    const issueBy = certificate?.issuerName
        ?.split(', ')
        .find((item: string) => item.includes(keyIssueByString))
        ?.replace(keyIssueByString, '');

    const dateTo = changeDateFormat(validToDate);

    return {
        email: emailKeyNumber || emailKeyString,
        surname: surnameKeyNumber || surnameKeyString,
        name: nameKeyNumber || nameKeyString,
        fullName: fullNameKeyString,
        issueBy,
        dateTo,
    };
};
