import { FormInstance } from 'antd';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { removeLocalStorageItem } from '~/utils/local-storage';

export const resetCertificateForm = (form: FormInstance<Partial<CertificateForm>>) => {
    removeLocalStorageItem(LOCAL_STORAGE.filtersForCertificates);
    removeLocalStorageItem(LOCAL_STORAGE.draftData);
    form.resetFields();
};
