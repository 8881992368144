import { FC } from 'react';
import { Card, Table } from 'antd';

import { returnArrayForTable } from '~/components/check-and-sign-certificate/content/shared/certificate-info/constants/return-array-for-table';
import { returnColumns } from '~/components/check-and-sign-certificate/content/shared/certificate-info/constants/return-columns';
import { SignerCertificateInfo } from '~/rtk-queries/types/certificate-verification';

export const CertificateInfo: FC<{ info: SignerCertificateInfo; result: boolean }> = ({
    info,
    result,
}) => (
    <Card>
        <Table
            dataSource={returnArrayForTable(info).filter((el) => el.columnResult)}
            columns={returnColumns(result)}
            pagination={false}
            showHeader={false}
            scroll={{ x: 200 }}
        />
    </Card>
);
