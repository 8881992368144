import ru from 'antd/es/date-picker/locale/ru_RU';

export const buddhistLocale: typeof ru = {
    ...ru,
    lang: {
        ...ru.lang,
        fieldDateFormat: 'DD-MM-YYYY',
        fieldDateTimeFormat: 'DD-MM-YYYY HH:mm:ss',
        yearFormat: 'YYYY',
        cellYearFormat: 'YYYY',
        shortWeekDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        shortMonths: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
    },
};
