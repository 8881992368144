import { Key, useCallback } from 'react';
import { Collapse, CollapseProps } from 'antd';

import styles from './result-block-with-collapse.module.css';

import { useCollapse } from '~/hooks/use-collapse';

type ResultBlockProps = {
    itemsForCollapse: CollapseProps['items'];
};

export const ResultBlockWithCollapse = ({ itemsForCollapse }: ResultBlockProps) => {
    const defaultOpeningCondition = useCallback(
        (key?: Key) => itemsForCollapse?.some((el) => el.key === key),
        [itemsForCollapse],
    );
    const { activeKeys, openCollapseHandler } = useCollapse({
        itemsForCollapse,
        defaultOpeningCondition,
    });

    return (
        <Collapse
            className={styles.collapse}
            ghost={true}
            items={itemsForCollapse}
            onChange={openCollapseHandler}
            defaultActiveKey={activeKeys}
        />
    );
};
