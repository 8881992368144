import { LifeStatus } from '~/constants/certificates-table';
import {
    CertificateApplication,
    CertificateBase,
    CertificateInstance,
    CertificatePreApplication,
    CertificateRevocation,
} from '~/utils/transform-certificates-data/type';

type CertificateType = Omit<CertificateBase, 'status'>;

export const isCertificatePreApplication = (
    data: CertificateType,
): data is CertificatePreApplication => data.lifeStatus === LifeStatus.preApplication;

export const isCertificateApplication = (data: CertificateType): data is CertificateApplication =>
    data.lifeStatus === LifeStatus.application;

export const isCertificateInstance = (data: CertificateType): data is CertificateInstance =>
    data.lifeStatus === LifeStatus.certificate;

export const isCertificateRevocation = (data: CertificateType): data is CertificateRevocation =>
    data.lifeStatus === LifeStatus.revocation;
