export const EnvelopeIcon = () => (
    <svg
        width='184'
        height='117'
        viewBox='0 0 184 117'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M25.875 83C9.85688 86.5947 0 91.4916 0 96.8884C0 107.921 41.1898 116.864 92 116.864C142.81 116.864 184 107.921 184 96.8884C184 91.4916 174.143 86.5947 158.125 83V91.708C158.125 97.7664 154.33 102.732 149.644 102.732H34.3563C29.67 102.732 25.875 97.7635 25.875 91.708V83Z'
            fill='black'
            fillOpacity='0.06'
        />
        <path
            d='M119.637 48.3155C119.637 43.7354 122.495 39.9543 126.04 39.9514H158.125V91.7082C158.125 97.7665 154.33 102.732 149.644 102.732H34.3562C29.67 102.732 25.875 97.7637 25.875 91.7082V39.9514H57.96C61.5049 39.9514 64.3626 43.7268 64.3626 48.3069V48.3697C64.3626 52.9498 67.252 56.6482 70.794 56.6482H113.206C116.748 56.6482 119.637 52.9156 119.637 48.3355V48.3155Z'
            fill='black'
            fillOpacity='0.04'
            stroke='black'
            strokeOpacity='0.15'
        />
        <path
            d='M158.125 40.2664L128.955 7.44366C127.555 5.20639 125.511 3.85376 123.358 3.85376H60.6424C58.489 3.85376 56.4449 5.20639 55.0448 7.44081L25.875 40.2693'
            stroke='black'
            strokeOpacity='0.15'
        />
    </svg>
);
