import { UploadFile } from 'antd/es/upload';

export enum SignDocumentFields {
    content = 'content',
    signatureType = 'signatureType',
    thumbprint = 'thumbprint',
}

export type SignedFormTypes = {
    [SignDocumentFields.content]: UploadFile[];
    [SignDocumentFields.signatureType]: boolean;
    [SignDocumentFields.thumbprint]: string;
};
