export const getFileExtension = (filename?: string): string => {
    if (!filename) return '';

    const parts = filename.split('.');

    if (parts.length === 1) {
        return '';
    }

    const extension = parts[parts.length - 1];

    return extension.toLowerCase();
};
