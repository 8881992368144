import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { SessionDataResponse } from '~/rtk-queries/types/auth';
import { AlertStatuses } from '~/types/common';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';
import { getLocalStorageItem } from '~/utils/local-storage';

export type AppState = typeof initialState;

const initialState = {
    isAuthenticated: !!getLocalStorageItem(LOCAL_STORAGE.isUserAuthenticated),
    session: null as SessionDataResponse | null,
    accessToken: '',
    alerts: [] as AlertStatuses[],
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAuthenticated: (state, actions: PayloadAction<boolean>) => {
            state.isAuthenticated = actions.payload;
        },
        setAccessToken(state, { payload: token }: PayloadAction<string>) {
            state.accessToken = token;
        },
        setSessionData: (state, actions: PayloadAction<SessionDataResponse | null>) => {
            state.session = actions.payload;
        },
        setPopupAlert(state, { payload }: PayloadAction<AlertStatuses>) {
            state.alerts.push(payload);
        },
        deleteFirstPopupAlert(state) {
            state.alerts.shift();
        },
        clearStateOnLogout: () => ({
            ...initialState,
            isAuthenticated: !!getLocalStorageItem(LOCAL_STORAGE.isUserAuthenticated),
        }),
    },
    selectors: {
        appSelector: (state) => state,
        accessTokenSelector: (state) => state.accessToken,
        popupAlertSelector: (state) => (isNotEmptyArray(state.alerts) ? state.alerts.at(0) : null),
        isAuthenticatedSelector: (state) => state.isAuthenticated,
    },
});

export const {
    setAccessToken,
    setAuthenticated,
    setSessionData,
    clearStateOnLogout,
    setPopupAlert,
    deleteFirstPopupAlert,
} = appSlice.actions;

export const { accessTokenSelector, appSelector, popupAlertSelector, isAuthenticatedSelector } =
    appSlice.selectors;
export const appReducer = appSlice.reducer;
