import { ColumnFilterItem } from 'antd/es/table/interface';

export const transformTitleFilters = (
    titleHashmap: Record<string, string>,
    filterTitles?: string[],
): ColumnFilterItem[] => {
    if (!filterTitles) {
        return [];
    }

    return filterTitles.map((title) => ({
        text: typeof titleHashmap[title] === 'undefined' ? title : titleHashmap[title],
        value: title,
    }));
};
