import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckCadesExtension } from './use-check-cades-extension';
import { useFirstRender } from './use-first-render';

import { ROUTES } from '~/constants/routes';
import { stepName } from '~/constants/step-name';
import { useLoaderContext } from '~/contexts/hooks';
import { StepParams } from '~/types/router';
import { FrontConfigType } from '~/utils/get-front-config/type';

export const useEnterPage = (frontConfig?: FrontConfigType) => {
    const { stepName: enterStep } = useParams<StepParams>();
    const { stopLoading } = useLoaderContext();
    const navigate = useNavigate();
    const isMount = useFirstRender();
    const [isOpenModalAccessConfirmation, setIsOpenModalAccessConfirmation] = useState(false);

    const {
        isInstalledEnviroment,
        browserLink,
        platformLink,
        cspLink,
        cadesStatus,
        extentionStatus,
        cspStatus,
        loadCadesPlugin,
    } = useCheckCadesExtension(frontConfig);

    const linkInstall =
        (enterStep === stepName.two && browserLink) ||
        (enterStep === stepName.three && platformLink) ||
        (enterStep === stepName.six && cspLink) ||
        undefined;

    const redirectToStep = useCallback(
        (step: stepName) => navigate(`${ROUTES.stepsRootPage}/${step}`),
        [navigate],
    );

    const checkEnviromentSteps = useCallback(() => {
        if (!cadesStatus) {
            redirectToStep(stepName.three);

            return;
        }
        if (!extentionStatus) {
            redirectToStep(stepName.two);

            return;
        }
        if (!cspStatus) {
            redirectToStep(stepName.six);
        }
    }, [cadesStatus, cspStatus, extentionStatus, redirectToStep]);

    const onClickElectronicSignature = useCallback(async () => {
        if (isMount) return;
        checkEnviromentSteps();
        redirectToStep(stepName.four);
    }, [checkEnviromentSteps, isMount, redirectToStep]);

    const onClickOtherLogin = useCallback(() => {
        redirectToStep(stepName.one);
    }, [redirectToStep]);

    const onClickInstallExtension = useCallback(() => {
        redirectToStep(stepName.three);
    }, [redirectToStep]);

    const onClickDownloadPlugin = useCallback(() => {
        redirectToStep(stepName.six);
    }, [redirectToStep]);

    const onClickCheckElectronicSignature = useCallback(() => {
        stopLoading();
        setIsOpenModalAccessConfirmation(true);
    }, [stopLoading]);

    useEffect(() => {
        if (isMount || enterStep === stepName.one) return;

        if (!loadCadesPlugin && isInstalledEnviroment) {
            if (enterStep === stepName.four || enterStep === stepName.five) return;
            redirectToStep(stepName.four);
        } else {
            checkEnviromentSteps();
        }
    }, [
        checkEnviromentSteps,
        enterStep,
        isInstalledEnviroment,
        isMount,
        loadCadesPlugin,
        redirectToStep,
    ]);

    return {
        isInstalledEnviroment,
        linkInstall,
        loadCadesPlugin,
        onClickElectronicSignature,
        onClickOtherLogin,
        onClickInstallExtension,
        onClickDownloadPlugin,
        onClickCheckElectronicSignature,
        isOpenModalAccessConfirmation,
        setIsOpenModalAccessConfirmation,
    };
};
