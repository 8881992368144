export const FORMS_DATA = {
    checkCertificateForm: {
        title: 'Сервис проверки статуса сертификата ключа проверки электронной подписи',
    },
    checkDocumentForm: {
        title: 'Сервис проверки электронной подписи подписанного документа',
        uploadTitle: 'Исходный документ',
        signatureTitle: 'Подписанный документ',
        uploadOneFile: 'Загрузить',
        uploadTwoFiles: 'Загрузить',
        continueDownloadingTheFile: 'Выберите тип подписи для начала загрузки файла/файлов',
    },
};
