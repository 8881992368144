import { Certificate } from './transform-certificates-data/type';

import { SendToArchiveParams } from '~/rtk-queries/types/certificates';

export const getRequestData = ({
    certificateId,
    certRequestId,
    revokeRequestId,
    lkRequestId,
    lkRevokeRequestId,
    lifeStatus,
}: Certificate) => {
    const params: SendToArchiveParams = {
        pkiEntityId: certificateId ?? certRequestId ?? revokeRequestId,
        lkRevokeRequestId,
        lkRequestId,
        lifeStatus,
    };

    if (params.lkRevokeRequestId) {
        delete params.pkiEntityId;
    }

    return params;
};
