import { Tag } from 'antd';

import styles from './custom-tag.module.css';

import { TagColorType } from '~/constants/certificates';

type CustomTagProps = {
    title: string;
    color: TagColorType;
};

export const CustomTag = ({ title, color }: CustomTagProps) => (
    <Tag
        style={{ borderColor: `${color.borderColor}` }}
        className={styles.tagText}
        bordered={true}
        color={color.bgColor}
    >
        {title}
    </Tag>
);
