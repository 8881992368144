import { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import Logo from '../../assets/logo-esia.png';

import styles from './link-services.module.css';

import { stepName } from '~/constants/step-name';
import { CustomButton } from '~/shared/custom-button';
import { CustomEnterLink } from '~/shared/custom-enter-link';
import { StepParams } from '~/types/router';

type LinkServicesProps = {
    title?: string;
    linkEsia?: string | null;
    linkInstall?: string;
};

export const LinkServices: FC<LinkServicesProps> = ({ linkEsia, title, linkInstall }) => {
    const { stepName: currentStep } = useParams<StepParams>();
    const isFirstScreen = currentStep === stepName.one;

    return (
        <Fragment>
            {linkInstall && (
                <CustomButton className='enterPageButton' type='primary'>
                    <CustomEnterLink to={linkInstall} target='_blank'>
                        {title}
                    </CustomEnterLink>
                </CustomButton>
            )}
            {linkEsia && isFirstScreen && (
                <CustomButton className='updatePageButtonWhithLink'>
                    <CustomEnterLink to={linkEsia}>
                        <img src={Logo} className={styles.logoEsia} alt='logo esia' />
                        {title}
                    </CustomEnterLink>
                </CustomButton>
            )}
        </Fragment>
    );
};
