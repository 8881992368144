import { ERROR_TEXTS, ModalTextVariant } from '../constants/modal';

import { getLastPluginError } from './plugin/get-last-plugin-error';

import { PluginError } from '~/constants/plugin';

export const getModalErrorText = (error: unknown, isInstall: boolean = false) => {
    const errorCode: string = getLastPluginError(error);

    let text: ModalTextVariant | string = ERROR_TEXTS[errorCode];

    if (errorCode === PluginError.cancel || errorCode === PluginError.cancelInInstall) {
        text = '';
    }

    if (isInstall && errorCode === PluginError.isNotInPersonalStorage) {
        text = ERROR_TEXTS[PluginError.keyUndefined];
    }

    const title =
        errorCode === PluginError.cancel || errorCode === PluginError.cancelInInstall
            ? ERROR_TEXTS[PluginError.cancel]
            : '';

    return { isError: true, text, title };
};
