import { cloneElement, ReactElement, useEffect } from 'react';
import { Form } from 'antd';

import { getChild } from '~/shared/item-container/helpers/get-child';
import { FormItemStatus } from '~/shared/types';

type ItemContainerProps<T> = {
    children: ReactElement;
    getStatus: (status: FormItemStatus) => void;
} & T;

export const ItemContainer = <T extends Record<string, unknown>>({
    getStatus,
    children,
    ...props
}: ItemContainerProps<T>) => {
    const { status } = Form.Item.useStatus();

    useEffect(() => {
        getStatus(status);
    }, [getStatus, status]);

    return cloneElement(getChild(children), { ...props });
};
