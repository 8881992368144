import { InfoCircleOutlined } from '@ant-design/icons';
import { Flex, Popconfirm, Tooltip, Typography } from 'antd';

import { TOOLTIP_TEXT_FOR_SAVE } from '../constants/general';

import styles from './actions.module.css';

import { ActionType, CertificateActions } from '~/constants/certificates';
import { LifeStatus } from '~/constants/certificates-table';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { generateDraftActionTooltip } from '~/utils/generate-draft-action-tooltip';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';
import { Certificate } from '~/utils/transform-certificates-data/type';

export const Actions = ({
    item,
    actionVariantHandler,
}: {
    item: Certificate;
    actionVariantHandler: (action: ActionType, data: Certificate) => void;
}) => {
    const isShowViewAnswerAction =
        isNotEmptyArray(item.details) && item.lifeStatus === LifeStatus.preApplication;

    const isSaveToFileAction = item?.actions?.includes(ActionType.saveToFile);

    const isAuthTypeMatches =
        typeof item.authType === 'undefined' ||
        item.authType === Number(localStorage.getItem(LOCAL_STORAGE.authType));

    return (
        <div className={styles.certActions}>
            <div className={styles.columnActions}>
                {isShowViewAnswerAction && (
                    <Typography.Link
                        className={styles.certAction}
                        onClick={() => actionVariantHandler(ActionType.viewAnswer, item)}
                    >
                        {CertificateActions[ActionType.viewAnswer]}
                    </Typography.Link>
                )}
                {item?.actions?.map((action) => {
                    switch (action) {
                        case ActionType.deleteDraft:
                            return (
                                <Popconfirm
                                    key={action}
                                    title='Удалить черновик?'
                                    onConfirm={() => actionVariantHandler(action, item)}
                                    okText='Да'
                                    cancelText='Нет'
                                >
                                    <Typography.Link className={styles.certAction}>
                                        {CertificateActions[action]}
                                    </Typography.Link>
                                </Popconfirm>
                            );

                        case ActionType.saveToFile:
                            return null;

                        case ActionType.continueDraft:
                            return (
                                <Flex align='center' gap={6} key={action}>
                                    <Typography.Link
                                        className={styles.certAction}
                                        disabled={!isAuthTypeMatches}
                                        onClick={() => actionVariantHandler(action, item)}
                                    >
                                        {CertificateActions[action]}
                                    </Typography.Link>

                                    {!isAuthTypeMatches && (
                                        <Tooltip title={generateDraftActionTooltip(item.authType)}>
                                            <InfoCircleOutlined
                                                className={styles.draftDisabledInfo}
                                            />
                                        </Tooltip>
                                    )}
                                </Flex>
                            );

                        default:
                            return (
                                <Typography.Link
                                    key={action}
                                    className={styles.certAction}
                                    onClick={() => actionVariantHandler(action, item)}
                                >
                                    {CertificateActions[action]}
                                </Typography.Link>
                            );
                    }
                })}
            </div>

            {isSaveToFileAction && (
                <Tooltip
                    align={{ offset: [0, -8] }}
                    className={styles.sideAction}
                    trigger={['hover']}
                    title={TOOLTIP_TEXT_FOR_SAVE}
                    zIndex={10}
                >
                    <div className={styles.sideAction}>
                        <Typography.Link
                            onClick={() => actionVariantHandler(ActionType.saveToFile, item)}
                        >
                            {CertificateActions[ActionType.saveToFile]}
                        </Typography.Link>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};
