import { useMemo } from 'react';

import { CreateUserInfo } from '~/rtk-queries/types/certificates';
import { UserInfoResponse } from '~/rtk-queries/types/user-info';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { getInitialCommonSection } from '~/utils/application-tab/get-initial-common-section';

export const useInitialForm = (
    userInfoData: UserInfoResponse | undefined,
    draft: CreateUserInfo | null,
) => {
    const initFormValues: CertificateForm = useMemo(() => {
        const dataDocumentType = userInfoData?.userInfo.documents[0]?.type?.value;
        const documentType =
            draft?.document?.type ?? (dataDocumentType ? Number(dataDocumentType) : undefined);

        return {
            ...getInitialCommonSection(draft, userInfoData?.userInfo),
            document: {
                type: documentType,
            },
        };
    }, [draft, userInfoData]);

    return { initFormValues };
};
