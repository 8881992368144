export enum AccountTab {
    applicationTab = 'applicationTab',
    certificateTab = 'certificateTab',
    checkCertificateTab = 'checkCertificateTab',
    mchdTab = 'mchdTab',
    archiveTab = 'archiveTab',
}

export const AccountTabLabel = {
    applicationTab: 'Новый сертификат',
    certificateTab: 'Перечень сертификатов',
    checkCertificateTab: 'Подписание и проверка',
    mchdTab: 'МЧД',
    archiveTab: 'Журнал',
};
