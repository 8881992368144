const ActionVariantsTitleArchive = {
    returnToList: 'Вернуть в перечень',
};

type ActionVariantsTitleTypeArchive = keyof typeof ActionVariantsTitleArchive;

export enum ActionVariantsTypeArchive {
    returnToList = 'returnToList',
}

export const ARCHIVE_ACTIONS: Record<
    ActionVariantsTypeArchive,
    (typeof ActionVariantsTitleArchive)[ActionVariantsTitleTypeArchive]
> = {
    [ActionVariantsTypeArchive.returnToList]: ActionVariantsTitleArchive.returnToList,
};
