import { AuthTypeLabel, DraftDisabledLabel } from '~/constants/auth-draft';
import { AuthType } from '~/constants/auth-type';

export const generateDraftActionTooltip = (authType?: AuthType) => {
    if (!authType) {
        return DraftDisabledLabel.Error;
    }

    return `${DraftDisabledLabel.Begining} ${AuthTypeLabel[authType]}, ${DraftDisabledLabel.Ending}`;
};
