import { Fragment, useEffect, useRef, useState } from 'react';
import { Alert, Divider, Form, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';

import styles from './check-certificate.module.css';

import { AdditionalChecks } from '~/components/check-and-sign-certificate/content/check-certificate/additional-checks';
import { returnItemsForCollapse } from '~/components/check-and-sign-certificate/content/check-certificate/helpers/return-items-for-collapse';
import {
    CheckCertificateFields,
    FormTypes,
} from '~/components/check-and-sign-certificate/content/check-certificate/types/form-types';
import { useGetSvsPolicy } from '~/components/check-and-sign-certificate/content/hooks/use-get-svs-policy';
import { BUTTON_TEXT } from '~/constants/button';
import { FORMS_DATA } from '~/constants/forms-data';
import { CERTIFICATE_FORMAT } from '~/constants/general';
import { SignDocumentText } from '~/constants/sign-document';
import { useCheckVerifyCertificateMutation } from '~/rtk-queries/endpoints/certificate-verification';
import { VerificationResult } from '~/rtk-queries/types/certificate-verification';
import { CustomButton } from '~/shared/custom-button';
import { CustomRow } from '~/shared/custom-row';
import { FormUpload } from '~/shared/files-upload';
import { getDefaultTooltipTitle } from '~/shared/files-upload/helpers/get-default-tooltip-title';
import { CustomForm } from '~/shared/form-components/custom-form';
import { FormWrapper } from '~/shared/form-components/form-wrapper';
import { ResultBlockWithCollapse } from '~/shared/result-block-with-collapse';
import { ValidateErrorEntity } from '~/types/common';
import { convertToBase64 } from '~/utils/convert-to-base64';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';

const CHECK_FILE_SIZE_MB = 10;
const AVAILABLE_FILE_TYPES = 'Доступные для загрузки файлы *.cer, *.crt, *.p7b, *.pem.';

export const CheckCertificate = () => {
    const [form] = Form.useForm<FormTypes>();
    const { isLoadingGetSvsPolicy, isCertificateVerifierExist, certificateVerifiers } =
        useGetSvsPolicy();
    const [disabledCheckCertificateElements, setDisabledCheckCertificateElements] = useState(false);

    const [
        checkVerifyCertificate,
        { data: verifyCertificate, isLoading: isLoadingCheckVerifyCertificate, isSuccess },
    ] = useCheckVerifyCertificateMutation();

    const fileNameRef = useRef<null | string>(null);
    const currentFileExist = isNotEmptyArray(useWatch(CheckCertificateFields.content, form));

    const isLoading = isLoadingGetSvsPolicy || isLoadingCheckVerifyCertificate;

    const onSave = async () => {
        try {
            const { content, certVerifiersPluginsIds } = await form.validateFields();

            const base64 = await Promise.all(
                content.map(async ({ originFileObj }) =>
                    originFileObj ? convertToBase64(originFileObj) : null,
                ),
            );

            checkVerifyCertificate({ content: base64[0], certVerifiersPluginsIds });
            fileNameRef.current = content[0].name;
        } catch (error) {
            const errorObj = error as ValidateErrorEntity<FormTypes>;

            form.scrollToField(errorObj.errorFields[0].name[0], {
                behavior: 'smooth',
                block: 'center',
            });
        } finally {
            setDisabledCheckCertificateElements(true);
        }
    };

    const checkAnotherCertificateHandler = () => {
        form.setFieldValue(CheckCertificateFields.content, []);
        fileNameRef.current = null;
        setDisabledCheckCertificateElements(false);
    };

    const itemsForCollapse = returnItemsForCollapse(
        fileNameRef.current ?? '',
        verifyCertificate?.verificationResult ?? ({} as VerificationResult),
    );

    const needToShowResult =
        currentFileExist && fileNameRef.current && !isLoadingCheckVerifyCertificate;

    const isShowCheckAnotherCertificateButton =
        currentFileExist && fileNameRef.current && isSuccess;
    const isDisabledCheckButton = isLoading || !currentFileExist;

    useEffect(() => {
        if (!currentFileExist && fileNameRef.current) {
            form.resetFields();
            fileNameRef.current = null;
            setDisabledCheckCertificateElements(false);
        }
    }, [currentFileExist, form]);

    return (
        <FormWrapper
            header={
                <CustomRow className={styles.titleContainer} columnSpan={24} rowGutter={[0, 12]}>
                    <Typography.Title className={styles.title} level={5}>
                        {FORMS_DATA.checkCertificateForm.title}
                    </Typography.Title>
                    <Alert
                        type='warning'
                        showIcon={true}
                        message={SignDocumentText.attention}
                        className={styles.alert}
                    />
                </CustomRow>
            }
            footer={
                <CustomButton
                    className='applicationTabButton'
                    disabled={isDisabledCheckButton}
                    onClick={
                        isShowCheckAnotherCertificateButton
                            ? checkAnotherCertificateHandler
                            : onSave
                    }
                >
                    {isShowCheckAnotherCertificateButton
                        ? BUTTON_TEXT.checkAnotherCertificate
                        : BUTTON_TEXT.checkCertificate}
                </CustomButton>
            }
        >
            <CustomForm layout='vertical' form={form} disabled={isLoading}>
                <CustomRow columnSpan={24} rowGutter={[0, 12]}>
                    <Fragment>
                        <FormUpload
                            name={CheckCertificateFields.content}
                            title={<h4>Файл сертификата</h4>}
                            fileListWidth={100}
                            accept={CERTIFICATE_FORMAT}
                            isRequired={true}
                            fileListBlock={true}
                            maxCount={1}
                            hideUploadButtonAfterLastFileUploaded={true}
                            isLoading={isLoadingCheckVerifyCertificate}
                            maxFileSizeMb={CHECK_FILE_SIZE_MB}
                            customTooltipTitle={
                                <span className={styles.tooltipTitle}>
                                    {AVAILABLE_FILE_TYPES}{' '}
                                    {getDefaultTooltipTitle(CHECK_FILE_SIZE_MB)}
                                </span>
                            }
                        />
                        {currentFileExist && <Divider style={{ marginBottom: 0, marginTop: 0 }} />}
                    </Fragment>
                    {isCertificateVerifierExist && currentFileExist && (
                        <AdditionalChecks
                            form={form}
                            certificateVerifiers={certificateVerifiers}
                            disabled={disabledCheckCertificateElements}
                        />
                    )}
                </CustomRow>
                {needToShowResult && (
                    <ResultBlockWithCollapse itemsForCollapse={itemsForCollapse} />
                )}
            </CustomForm>
        </FormWrapper>
    );
};
