import classNames from 'classnames';

import Logo from './assets/logo.png';
import LogoWhite from './assets/logo_white.png';

import styles from './logo.module.css';

type LogoBlockProps = {
    type: string;
};

export const LogoBlock = (type: LogoBlockProps) => (
    <img
        src={type.type === 'white' ? LogoWhite : Logo}
        className={classNames(styles.logoImg, { [styles.logoImgWhite]: type.type === 'white' })}
        alt='logo'
    />
);
