import { useState } from 'react';
import classNames from 'classnames';

import styles from './mchd-tab.module.css';

import { MCHTD } from '~/constants/mchd';
import { WithLoader } from '~/hoc/with-loader';
import { useGetFrontConfigQuery } from '~/rtk-queries/endpoints/front-config';
import { FrontConfigKey } from '~/utils/get-front-config/type';

export const MchdTab = () => {
    const {
        data: frontConfig,
        isLoading: isLoadingFrontConfig,
        isSuccess: isSuccessFrontConfig,
    } = useGetFrontConfigQuery();
    const [isLoadFrame, setIsLoadFrame] = useState(false);

    const onLoadFrame = () => {
        setIsLoadFrame(true);
    };

    return (
        <WithLoader isLoading={!(!isLoadingFrontConfig || isLoadFrame || isSuccessFrontConfig)}>
            <iframe
                src={frontConfig ? frontConfig[FrontConfigKey.MCHDLink] : MCHTD.src}
                className={classNames(
                    styles.mchdTabFrame,
                    isLoadFrame && styles.mchdTabFrameVisible,
                )}
                title={MCHTD.title}
                onLoad={onLoadFrame}
            />
        </WithLoader>
    );
};
