import { CertificateVerifier } from '~/rtk-queries/types/certificate-verification';
import { OptionType } from '~/types/common';

export const returnOptions = (certificateVerifiers: CertificateVerifier[]) =>
    certificateVerifiers.reduce(
        (acc, { id, pluginDescription, checkByDefaultRequired }) => {
            acc.options.push({ label: pluginDescription, value: id });
            if (checkByDefaultRequired) {
                acc.defaultValue.push(id);
            }

            return acc;
        },
        { options: [], defaultValue: [] } as {
            options: OptionType[];
            defaultValue: number[];
        },
    );
