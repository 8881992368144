import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { ERROR_TEXTS } from '~/constants/modal';
import { PluginError } from '~/constants/plugin';
import { transformErrorWithAlert } from '~/rtk-queries/helpers/transform-error-with-alert';

export const createAlertError = (error: string) => {
    const errorResponse: FetchBaseQueryError = {
        status: 'CUSTOM_ERROR',
        error: ERROR_TEXTS[error],
    };

    return transformErrorWithAlert(errorResponse, ERROR_TEXTS[PluginError.cancel]);
};
