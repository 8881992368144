import { UploadFile } from 'antd/es/upload';

export enum CheckCertificateFields {
    content = 'content',
    certVerifiersPluginsIds = 'certVerifiersPluginsIds',
}

export type FormTypes = {
    [CheckCertificateFields.content]: UploadFile[];
    [CheckCertificateFields.certVerifiersPluginsIds]?: number[];
};
