import { ColumnsType } from 'antd/es/table';

import { SORT_ORDERS } from '~/constants/general';
import { CommonFilters, TableFilter } from '~/types/common';

type AddColumnDefaultSortType<T> = {
    columns: ColumnsType<T>;
    currentSortColumn: string | null;
    currentSortOrder: string | null;
    currentFilters: CommonFilters;
    tableId?: number;
    currentTableId?: string;
};

export const addColumnDefaultSortAndDefaultFilter = <T extends Record<string, unknown>>({
    columns,
    currentSortColumn,
    currentSortOrder,
    currentFilters,
}: AddColumnDefaultSortType<T>) => {
    const reverseSortObject = Object.fromEntries(
        Object.entries(SORT_ORDERS).map((item) => item.reverse()),
    );

    const sortValue = currentSortOrder ? reverseSortObject[currentSortOrder] : null;

    const filterArrayKeys = Object.keys(currentFilters);

    return columns.map((column) => {
        const { key } = column;
        const keyAsString = key as TableFilter;
        const isFieldSortingChanged = keyAsString === currentSortColumn && currentSortOrder;
        const isFieldFilterExist =
            filterArrayKeys.includes(keyAsString) && currentFilters[keyAsString];

        return {
            ...column,
            sortOrder: isFieldSortingChanged ? sortValue : null,
            filteredValue: isFieldFilterExist ? currentFilters[keyAsString] : null,
        };
    });
};
