import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, Tabs, TabsProps } from 'antd';

import styles from './custom-tabs.module.css';

import { AccountTab } from '~/constants/account-tab';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { useAppDispatch, useAppSelector } from '~/hooks';
import {
    appTabSelector,
    setOpenModalForMoveFromApplicationTab,
} from '~/store/app-tab/app-tab-slice';
import { removeLocalStorageItem } from '~/utils/local-storage';

type CustomTabsProps = {
    items: TabsProps['items'];
    basePath: string;
    startingTabKey: string;
};

export const CustomTabs: FC<CustomTabsProps> = ({ items, basePath, startingTabKey }) => {
    const { tabName } = useParams();
    const navigate = useNavigate();
    const { search } = useLocation();
    const { isOpenModalForMoveFromApplicationTab } = useAppSelector(appTabSelector);
    const titleModal = 'Данные будут сброшены. \nВы уверены, что хотите перейти?';

    const dispatch = useAppDispatch();
    const [modal, contextHolder] = Modal.useModal();

    const startParamsObj = useMemo(() => {
        const emptyObject: { [key: string]: string } = {};

        items?.forEach((item) => {
            emptyObject[item.key] = '';
        });

        return emptyObject;
    }, [items]);

    const tabSearchRef = useRef<{ [key: string]: string }>(startParamsObj);

    const isTabExist = items?.some((item) => item?.key === tabName);

    const onOkHandler = useCallback(
        (activeKey: string, params: string) => {
            removeLocalStorageItem(LOCAL_STORAGE.draftData);

            dispatch(setOpenModalForMoveFromApplicationTab(false));
            navigate(`${basePath}/${activeKey}${params}`);
        },
        [basePath, dispatch, navigate],
    );

    const showModalForMoveFromApplicationTab = useCallback(
        (activeKey: string, params: string) => {
            modal.confirm({
                title: <span style={{ whiteSpace: 'pre-wrap' }}>{titleModal}</span>,
                closable: true,
                icon: <ExclamationCircleFilled />,
                centered: true,
                okText: 'Перейти',
                cancelText: 'Отмена',
                okButtonProps: {
                    danger: true,
                },
                onOk() {
                    onOkHandler(activeKey, params);
                },
            });
        },
        [modal, onOkHandler],
    );

    const handleTabClick = (activeKey: string) => {
        tabSearchRef.current = { ...tabSearchRef.current, [String(tabName)]: search };
        const params = tabSearchRef.current[activeKey] ?? '';

        const errorOpenModal =
            isOpenModalForMoveFromApplicationTab &&
            tabName === AccountTab.applicationTab &&
            activeKey !== AccountTab.applicationTab;

        if (errorOpenModal) {
            showModalForMoveFromApplicationTab(activeKey, params);
        } else {
            if (activeKey !== AccountTab.applicationTab) {
                removeLocalStorageItem(LOCAL_STORAGE.draftData);
            }
            navigate(`${basePath}/${activeKey}${params}`);
        }
    };

    useEffect(() => {
        if (!tabName) {
            navigate(`${basePath}/${startingTabKey}`);
        }
    }, [basePath, navigate, startingTabKey, tabName]);

    if (!isTabExist && tabName) {
        return <Navigate to={basePath} replace={true} />;
    }

    return (
        <div>
            <Tabs
                className={styles.tabs}
                activeKey={tabName}
                onTabClick={handleTabClick}
                items={items}
                tabBarGutter={28}
            />
            {isOpenModalForMoveFromApplicationTab && <div>{contextHolder}</div>}
        </div>
    );
};
