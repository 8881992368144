import { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Typography } from 'antd';

import { UnlockIconComponent } from '../../assets/unlock-icon-component';

import styles from './access-loader-modals.module.css';

import { BUTTON_TEXT } from '~/constants/button';
import { ENTER } from '~/constants/enter';
import { ROUTES } from '~/constants/routes';
import { stepName } from '~/constants/step-name';
import { useLoaderContext } from '~/contexts/hooks';
import { CustomButton } from '~/shared/custom-button';
import { CustomModal } from '~/shared/custom-modal';
import { CustomModalTitle } from '~/shared/custom-modal-title';
import { CustomSpinner } from '~/shared/custom-spinner';
import { StepParams } from '~/types/router';

type AccessLoaderModalsProps = {
    setIsOpenModalAccessConfirmation: Dispatch<SetStateAction<boolean>>;
    isOpenModalAccessConfirmation: boolean;
};

export const AccessLoaderModals: FC<AccessLoaderModalsProps> = ({
    setIsOpenModalAccessConfirmation,
    isOpenModalAccessConfirmation,
}) => {
    const { stepName: currentStep } = useParams<StepParams>();
    const { isLoading, setIsLoading } = useLoaderContext();
    const navigate = useNavigate();

    const onClickYes = () => {
        setIsOpenModalAccessConfirmation(false);
        navigate(`${ROUTES.stepsRootPage}/${stepName.five}`);
    };

    const onClickNo = () => {
        setIsOpenModalAccessConfirmation(false);
        navigate(`${ROUTES.stepsRootPage}/${stepName.one}`);
    };
    const isFourthStep = currentStep === stepName.four;

    return (
        <Fragment key='access loader footer block'>
            <CustomModal
                key='loading modal'
                setOpenModal={setIsLoading}
                open={isLoading && isFourthStep}
                isClose={false}
                footer={false}
            >
                <Space direction='vertical' align='center' className={styles.content}>
                    <CustomSpinner />
                    {ENTER.loadingModalText}
                </Space>
            </CustomModal>
            <CustomModal
                key='confirmation modal'
                setOpenModal={setIsOpenModalAccessConfirmation}
                open={isOpenModalAccessConfirmation && isFourthStep}
                isClose={true}
                footer={[
                    <Space size='large' key='footer confirmation modal'>
                        <CustomButton key={BUTTON_TEXT.no} onClick={onClickNo}>
                            {BUTTON_TEXT.no}
                        </CustomButton>
                        <CustomButton
                            key={BUTTON_TEXT.yes}
                            className='enterPageButtonYes'
                            onClick={onClickYes}
                        >
                            {BUTTON_TEXT.yes}
                        </CustomButton>
                    </Space>,
                ]}
            >
                <Space direction='vertical' align='center' className={styles.modalContent}>
                    <CustomModalTitle
                        title={ENTER.accessConfirmationModal.title}
                        icon={<UnlockIconComponent />}
                    />
                    {ENTER.accessConfirmationModal.text.map((item) => (
                        <Typography.Text key={item}>{item}</Typography.Text>
                    ))}
                </Space>
            </CustomModal>
        </Fragment>
    );
};
