import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { CommonResponse } from '~/types/common';

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError =>
    typeof error === 'object' && error !== null && 'status' in error;

export const isErrorWithDataMessage = (
    error: FetchBaseQueryError,
): error is FetchBaseQueryError & { data: CommonResponse } =>
    typeof error.data === 'object' &&
    error.data !== null &&
    'message' in error.data &&
    typeof error.data.message === 'string';
