import { AuthType } from './auth-type';

export const AuthTypeLabel = {
    [AuthType.Esia]: 'через Госуслуги',
    [AuthType.Signature]: 'по электронной подписи',
};

export const DraftDisabledLabel = {
    Begining: 'Авторизуйтесь',
    Ending: 'чтобы открыть черновик',
    Error: 'Невозможно открыть черновик',
};

export const DRAFT_FORM_TOOLTIP =
    'Просмотр и редактирование черновика будет возможно только при авторизации';
