import { fetchAllCertificatesInSystem } from './fetch-all-certificates-in-system';
import { getLastPluginError } from './get-last-plugin-error';

// Асинхронная функция для получения данных о конкретном сертификате по его идентификатору
export const fetchCurrentCertificateData = async (thumbprint: string) => {
    try {
        // Получение данных о всех сертификатах в системе
        const allCertificates = await fetchAllCertificatesInSystem();

        if (Array.isArray(allCertificates)) {
            // Фильтрация сертификатов по серийному номеру для получения нужного сертификата
            const currentCertificate = allCertificates.find(
                (certificate: { signByThumbprint: string }) =>
                    // заменить MOCK_CERTIFICATE_ID на certId, который будет браться из таблицы
                    certificate?.signByThumbprint === thumbprint,
            );

            // Возврат данных о найденном сертификате
            return currentCertificate;
        }

        return getLastPluginError(allCertificates);
    } catch (err) {
        return getLastPluginError(err);

        // Обработка возможных ошибок и возвращение null в случае их возникновения
    }
};
