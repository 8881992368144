import { UploadFile } from 'antd/es/upload';

import { convertToBase64 } from '~/utils/convert-to-base64';

export const returnBase64Array = async (content: UploadFile[]) => {
    const base64Array = await Promise.all(
        content.map(async ({ originFileObj }) =>
            originFileObj ? convertToBase64(originFileObj) : null,
        ),
    );

    const originalDocument = content.length > 1 ? content[1].name : content[0].name;

    return { base64Array, originalDocument };
};
