import { API } from '../constants/api';
import { interceptor } from '../interceptor';

import { getFrontConfig } from '~/utils/get-front-config/get-front-config';

export const frontConfig = interceptor.injectEndpoints({
    endpoints: (builder) => ({
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        getFrontConfig: builder.query<any, void>({
            query: () => ({
                url: API.getFrontConfig,
                method: 'GET',
            }),
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            transformResponse: (response: any) => getFrontConfig(response.links),
        }),
    }),
});

export const { useGetFrontConfigQuery } = frontConfig;
