import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/es/table/interface';
import dayjs from 'dayjs';

import { RangePickerDropdown } from '../../certificates-table/range-picker-dropdown';

import { ActionVariantsTypeArchive, ARCHIVE_ACTIONS } from '~/constants/archive';
import { CertificatesStatusInfo, StatusCode } from '~/constants/certificates';
import { CERTIFICATES_COLUMNS, CertificatesColumnsName } from '~/constants/certificates-table';
import { FORMAT_DATE_LOCALE } from '~/constants/general';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { SendToArchiveParams } from '~/rtk-queries/types/certificates';
import { CertificatesTitle } from '~/shared/certificates-title';
import { CustomTag } from '~/shared/custom-tag';
import { getRequestData } from '~/utils/get-request-data';
import { Certificate } from '~/utils/transform-certificates-data/type';

type ReturnColumnsForArchiveTableType = {
    allTitles: ColumnFilterItem[];
    unarchiveRequest: (request: SendToArchiveParams) => void;
};
export const returnColumnsForArchiveTable = ({
    allTitles,
    unarchiveRequest,
}: ReturnColumnsForArchiveTableType): ColumnsType<Certificate> => {
    const filterDropdown = ({ ...props }: FilterDropdownProps) => (
        <RangePickerDropdown filterName={LOCAL_STORAGE.filtersForArchives} {...props} />
    );

    const unarchive = (data: Certificate) => {
        const requestData = getRequestData(data);

        unarchiveRequest(requestData);
    };

    return [
        // TODO колонки для таблицы такие же как и для таблицы сертификатов - дублирование. Общие колонки можно вынести в отдельную константу.
        {
            key: CertificatesColumnsName.title,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.title],
            dataIndex: CertificatesColumnsName.title,
            filters: allTitles,
            filterOnClose: false,
            filterSearch: true,
            sorter: true,
            render: (text) => <CertificatesTitle title={text} />,
            fixed: true,
        },
        {
            key: CertificatesColumnsName.notBefore,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.notBefore],
            dataIndex: CertificatesColumnsName.notBefore,
            sorter: true,
            render: (date) => <span>{date ? dayjs(date).format(FORMAT_DATE_LOCALE) : '-'}</span>,
            width: 'var(--width-for-column-150)',
        },
        {
            key: CertificatesColumnsName.notAfter,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.notAfter],
            dataIndex: CertificatesColumnsName.notAfter,
            sorter: true,
            render: (date) => <span>{date ? dayjs(date).format(FORMAT_DATE_LOCALE) : '-'}</span>,
            width: 'var(--width-for-column-150)',
        },
        {
            key: CertificatesColumnsName.statusTag,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.statusTag],
            dataIndex: CertificatesColumnsName.statusTag,
            filters: Object.keys(CertificatesStatusInfo)
                .filter(
                    (_, idx) =>
                        idx !== StatusCode.ApplicationCompleted && idx !== StatusCode.Draft - 1,
                )
                .map((element) => ({
                    text: CertificatesStatusInfo[Number(element) as StatusCode].titleStatus,
                    value: Number(element),
                })),
            filterOnClose: false,
            filterSearch: true,
            sorter: true,
            render: (item) => <CustomTag title={item.titleStatus} color={item.color} />,
        },
        {
            key: CertificatesColumnsName.lastEditDate,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.lastEditDate],
            dataIndex: CertificatesColumnsName.lastEditDate,
            filterDropdown,
            filterSearch: true,
            sorter: true,
            width: 'var(--width-for-column-150)',
        },
        {
            key: CertificatesColumnsName.actionsVariant,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.actionsVariant],
            dataIndex: CertificatesColumnsName.actionsVariant,
            render: (_, item) => (
                <Typography.Link onClick={() => unarchive(item)}>
                    {ARCHIVE_ACTIONS[ActionVariantsTypeArchive.returnToList]}
                </Typography.Link>
            ),
            width: 'var(--width-for-column-220)',
        },
    ];
};
