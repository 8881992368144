import { FC } from 'react';
import { Button, ButtonProps, Form, Space } from 'antd';
import classNames from 'classnames';

import styles from './form-sent-buttons.module.css';

type FormSentButtonsProps = {
    handleCancel: () => void;
    iconSaveButton?: ButtonProps['icon'];
    disabled?: boolean;
};

export const FormSentButtons: FC<FormSentButtonsProps> = ({
    handleCancel,
    disabled,
    iconSaveButton,
}) => (
    <Space className={styles.container}>
        <Form.Item noStyle={true}>
            <Button
                className={classNames({ [styles.button]: disabled })}
                onClick={handleCancel}
                type='link'
                disabled={disabled}
            >
                Сбросить
            </Button>
        </Form.Item>
        <Form.Item noStyle={true}>
            <Button icon={iconSaveButton} type='primary' htmlType='submit'>
                ОК
            </Button>
        </Form.Item>
    </Space>
);
