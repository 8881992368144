import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, FormItemProps, Upload, UploadProps } from 'antd';
import { Rule } from 'antd/es/form';
import { UploadFile } from 'antd/es/upload';
import classNames from 'classnames';

import { FileUploadTooltip } from '../file-upload-tooltip';

import { DEFAULT_BUTTON_TEXT } from './constants/general';
import { getDefaultTooltipTitle } from './helpers/get-default-tooltip-title';
import { getRequiredRule } from './helpers/get-required-rule';
import { FormUploadLabel } from './form-upload-label';

import styles from './form-upload.module.css';

import { SignatureSelection } from '~/components/check-and-sign-certificate/content/check-document/constants/signature-selection-options';
import { DEFAULT_FILE_SIZE } from '~/constants/general';
import { CustomSpace } from '~/shared/custom-space';
import { FileItem } from '~/shared/files-upload/file-item/file-item';
// eslint-disable-next-line import/no-extraneous-dependencies
import { transformMegabyteToByte } from '~/shared/files-upload/helpers/transform-megabyte-to-byte';
import { CustomFormItem } from '~/shared/form-components/custom-form-item';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';

export type FilesUploadProps = {
    name: string;
    accept?: string;
    showFileListAboveButton?: boolean;
    hideUploadButtonAfterLastFileUploaded?: boolean;
    hideUploadButton?: boolean;
    maxCount?: number;
    showExtensionIcon?: boolean;
    showRemoveIcon?: boolean;
    showTooltipIcon?: boolean;
    fileListBlock?: boolean;
    fileListWidth?: string | number;
    maxFileSizeMb?: number;
    isRequired?: boolean;
    rules?: Rule[];
    validateTrigger?: FormItemProps['validateTrigger'];
    multiple?: boolean;
    buttonText?: string;
    buttonIcon?: ReactNode;
    title?: ReactNode;
    isLabelDelete?: boolean;
    disabledUploadButton?: boolean;
    isLoading?: boolean;
    minCount?: number;
    customTooltipTitle?: string | ReactNode;
    prefix?: string;
    currentSignatureSelect?: SignatureSelection;
};

export const FormUpload = ({
    name,
    accept,
    title,
    maxCount,
    showExtensionIcon = false,
    showRemoveIcon = true,
    showFileListAboveButton = false,
    hideUploadButtonAfterLastFileUploaded = false,
    hideUploadButton = false,
    showTooltipIcon = true,
    maxFileSizeMb = DEFAULT_FILE_SIZE,
    fileListBlock = false,
    fileListWidth = 320,
    isRequired = false,
    rules,
    validateTrigger,
    multiple,
    buttonText = DEFAULT_BUTTON_TEXT,
    buttonIcon = <UploadOutlined />,
    isLabelDelete,
    disabledUploadButton,
    isLoading = false,
    minCount,
    customTooltipTitle,
    prefix,
    currentSignatureSelect,
}: FilesUploadProps) => {
    const [uploadError, setUploadError] = useState(false);
    const [currentFileList, setCurrentFileList] = useState<UploadFile[]>();
    const [tooBigFile, setTooBigFile] = useState(false);
    const [fileFormatError, setFileFormatError] = useState(false);
    const tooltipTitle = customTooltipTitle || getDefaultTooltipTitle(maxFileSizeMb);
    const availableFileTypes = React.isValidElement(customTooltipTitle)
        ? React.Children.toArray(customTooltipTitle.props.children)[0]
        : customTooltipTitle;

    const maxFileSize = transformMegabyteToByte(maxFileSizeMb);

    const isNotShowButton =
        isNotEmptyArray(currentFileList) &&
        maxCount === currentFileList.length &&
        hideUploadButtonAfterLastFileUploaded;

    const requiredRule = getRequiredRule(isRequired, minCount);

    const handleUpload: UploadProps['onChange'] = ({ fileList: newFileList, file }) => {
        const ALLOWED_FORMATS = accept?.split(',').map((item) => item.slice(1));

        const { size, name: fileName } = file;
        const currentFormat =
            ALLOWED_FORMATS && !ALLOWED_FORMATS.includes(fileName.split('.').at(-1) || '');
        const isSizeTooBig = size && maxFileSize && size >= maxFileSize;

        setTooBigFile(!!isSizeTooBig);
        setFileFormatError(!!currentFormat);

        if (isSizeTooBig || currentFormat) {
            return currentFileList ?? [];
        }

        return newFileList;
    };

    const itemRender = useCallback(
        (
            _: ReactElement,
            file: UploadFile,
            fileList: UploadFile[],
            actions: { download: () => void; preview: () => void; remove: () => void },
        ) => (
            <FileItem
                file={file}
                fileList={fileList}
                showExtensionIcon={showExtensionIcon}
                showRemoveIcon={showRemoveIcon}
                remove={isLoading ? actions.download : actions.remove}
                setCurrentFileList={setCurrentFileList}
                isLoading={isLoading}
                prefix={prefix}
            />
        ),
        [isLoading, showExtensionIcon, showRemoveIcon, prefix],
    );

    useEffect(() => {
        setTooBigFile(false);
        setFileFormatError(false);
    }, [currentSignatureSelect]);

    return (
        <div>
            {!isLabelDelete && hideUploadButton && (
                <CustomSpace direction='horizontal' size={5} className={styles.customLabel}>
                    {title}
                    {showTooltipIcon && <FileUploadTooltip title={tooltipTitle} />}
                </CustomSpace>
            )}
            <CustomFormItem
                className={classNames(styles.formItem, styles.itemControl)}
                style={{ width: '100%', maxWidth: fileListBlock ? undefined : fileListWidth }}
                name={name}
                label={
                    title && (
                        <FormUploadLabel
                            showTooltipIcon={showTooltipIcon}
                            title={title}
                            tooltipTitle={tooltipTitle}
                        />
                    )
                }
                valuePropName='fileList'
                rules={rules ?? [requiredRule]}
                validateTrigger={rules ? validateTrigger : undefined}
                getValueFromEvent={handleUpload}
                getStatus={(status) => setUploadError(status === 'error')}
            >
                <Upload
                    listType='text'
                    beforeUpload={() => false}
                    maxCount={maxCount}
                    accept={accept}
                    className={classNames(styles.upload, {
                        [styles.switchUploadItems]: showFileListAboveButton,
                        [styles.switchUploadFileList]:
                            showFileListAboveButton && isNotEmptyArray(currentFileList),
                    })}
                    itemRender={itemRender}
                    multiple={multiple}
                    disabled={disabledUploadButton}
                >
                    {isNotShowButton || hideUploadButton ? null : (
                        <Button
                            icon={buttonIcon}
                            danger={uploadError}
                            disabled={disabledUploadButton}
                        >
                            {buttonText}
                        </Button>
                    )}
                </Upload>
            </CustomFormItem>
            {fileFormatError && <span className={styles.errorFile}>{availableFileTypes}</span>}{' '}
            {tooBigFile && (
                <span className={styles.errorFile}>{getDefaultTooltipTitle(maxFileSizeMb)}</span>
            )}
        </div>
    );
};
