import { Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/es/table/interface';
import dayjs from 'dayjs';

import { Actions } from '../actions/actions';
import { Documents } from '../documents';
import { RangePickerDropdown } from '../range-picker-dropdown';

import {
    ActionType,
    CertificatesStatusInfo,
    PrintTemplateType,
    StatusCode,
} from '~/constants/certificates';
import { CERTIFICATES_COLUMNS, CertificatesColumnsName } from '~/constants/certificates-table';
import { FORMAT_DATE_LOCALE } from '~/constants/general';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { CertificatesTitle } from '~/shared/certificates-title';
import { CustomTag } from '~/shared/custom-tag';
import { Certificate } from '~/utils/transform-certificates-data/type';

type ReturnColumnsForCertificatesTableType = {
    actionVariantHandler: (action: ActionType, data: Certificate) => void;
    printHandler: (templateId: PrintTemplateType, data: Certificate) => void;
    allTitles: ColumnFilterItem[];
};
export const returnColumnsForCertificatesTable = ({
    allTitles,
    printHandler,
    actionVariantHandler,
}: ReturnColumnsForCertificatesTableType): ColumnsType<Certificate> => {
    const filterDropdown = ({ ...props }: FilterDropdownProps) => (
        <RangePickerDropdown filterName={LOCAL_STORAGE.filtersForCertificates} {...props} />
    );

    return [
        // TODO колонки для таблицы такие же как и для таблицы архива - дублирование. Общие колонки можно вынести в отдельную константу.
        {
            key: CertificatesColumnsName.title,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.title],
            dataIndex: CertificatesColumnsName.title,
            filters: allTitles,
            filterOnClose: false,
            filterSearch: true,
            sorter: true,
            render: (text) => <CertificatesTitle title={text} />,
            width: 'var(--width-for-column-400)',
            fixed: true,
        },
        {
            key: CertificatesColumnsName.notBefore,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.notBefore],
            dataIndex: CertificatesColumnsName.notBefore,
            sorter: true,
            render: (date) => <span>{date ? dayjs(date).format(FORMAT_DATE_LOCALE) : '-'}</span>,
            width: 'var(--width-for-column-150)',
        },
        {
            key: CertificatesColumnsName.notAfter,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.notAfter],
            dataIndex: CertificatesColumnsName.notAfter,
            sorter: true,
            render: (date) => <span>{date ? dayjs(date).format(FORMAT_DATE_LOCALE) : '-'}</span>,
            width: 'var(--width-for-column-150)',
        },
        {
            key: CertificatesColumnsName.statusTag,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.statusTag],
            dataIndex: CertificatesColumnsName.statusTag,
            filters: Object.keys(CertificatesStatusInfo).map((element) => ({
                text: CertificatesStatusInfo[Number(element) as StatusCode].titleStatus,
                value: Number(element),
            })),
            filterOnClose: false,
            filterSearch: true,
            sorter: true,
            render: (item) => (
                <Flex justify='center'>
                    <CustomTag title={item.titleStatus} color={item.color} />
                </Flex>
            ),
        },
        {
            key: CertificatesColumnsName.lastEditDate,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.lastEditDate],
            dataIndex: CertificatesColumnsName.lastEditDate,
            filterDropdown,
            filterSearch: true,
            sorter: true,
            width: 'var(--width-for-column-150)',
        },
        {
            key: CertificatesColumnsName.actionsVariant,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.actionsVariant],
            dataIndex: CertificatesColumnsName.actionsVariant,
            render: (_, item) => (
                <Actions item={item} actionVariantHandler={actionVariantHandler} />
            ),
            width: 'var(--width-for-column-220)',
        },
        {
            key: CertificatesColumnsName.documents,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.documents],
            dataIndex: CertificatesColumnsName.documents,
            render: (variants, item) =>
                variants.map((document: PrintTemplateType) => (
                    <Documents
                        key={document}
                        item={item}
                        printHandler={printHandler}
                        variant={document}
                    />
                )),
            width: 'var(--width-for-column-220)',
        },
    ];
};
