import { Navigate, RouteObject } from 'react-router-dom';

import { esiaRedirect } from './esia-redirect';
import { mainLayout } from './main-layout';
import { stepsRoot } from './steps-root';

import { Layout } from '~/components/layout';
import { ROUTES } from '~/constants/routes';
import { stepName } from '~/constants/step-name';

export const startPage: RouteObject = {
    path: ROUTES.startPage,
    element: <Layout />,
    children: [
        {
            index: true,
            element: <Navigate to={`${ROUTES.stepsRootPage}/${stepName.one}`} />,
        },
        stepsRoot,
        esiaRedirect,
        mainLayout,
    ],
};
