import React from 'react';
import { Tag, Typography } from 'antd';

import { EnrichmentStatusAlertType } from '../constants/view-answer';

import styles from '../details-section/details-section.module.css';

import { EnrichmentStatusOut, EnrichmentStatusOutText } from '~/constants/certificates-table';

export const DetailsItem = ({
    enricher,
    status,
}: {
    enricher: string;
    status: EnrichmentStatusOut;
}) => (
    <React.Fragment>
        <Typography.Text className={styles.alignLeft}>
            <b>{enricher}</b>
        </Typography.Text>
        <Tag color={EnrichmentStatusAlertType[status]} className={styles.alignRight}>
            {EnrichmentStatusOutText[status]}
        </Tag>
    </React.Fragment>
);
