import { DatePicker, Form } from 'antd';
import { Rule } from 'antd/es/form';

import styles from './custom-data-picker.module.css';

import { buddhistLocale as locale } from '~/constants/date-format';
import { DATE_FORMAT } from '~/constants/general';
import { useCustomSelectProps } from '~/hooks/use-custom-props';
import { CustomTooltip } from '~/shared/custom-tooltip';

type CustomDataPickerProps = {
    currentValue?: string;
    label?: string;
    name?: string | string[];
    rules?: Rule[];
    disabled?: boolean;
};

export const CustomDataPicker = ({
    currentValue,
    label,
    name,
    rules,
    disabled,
    ...restProps
}: CustomDataPickerProps) => {
    const { titleForTooltip, placeholder, backgroundColorDisabled } = useCustomSelectProps(
        currentValue,
        disabled || false,
        label,
    );

    return (
        <CustomTooltip title={titleForTooltip}>
            <Form.Item className={styles.inputItem} {...restProps} {...{ name, rules, label }}>
                <DatePicker
                    className={styles.input}
                    format={{
                        format: DATE_FORMAT,
                        type: 'mask',
                    }}
                    style={backgroundColorDisabled}
                    {...{ disabled, placeholder, locale }}
                />
            </Form.Item>
        </CustomTooltip>
    );
};
