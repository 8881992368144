import { FC, ReactNode } from 'react';
import { Space } from 'antd';
import classNames from 'classnames';

import styles from './form-upload-label.module.css';

import { FileUploadTooltip } from '~/shared/file-upload-tooltip';

export type FileLabelProps = {
    title: ReactNode;
    tooltipTitle: string | ReactNode;
    showTooltipIcon: boolean;
    isError?: boolean;
};
export const FormUploadLabel: FC<FileLabelProps> = ({
    title,
    tooltipTitle,
    isError,
    showTooltipIcon,
}) => (
    <Space align='center' size={5} className={classNames({ [styles.errorText]: isError })}>
        {typeof title === 'string' ? <span className={styles.title}>{title}</span> : title}
        {showTooltipIcon && <FileUploadTooltip title={tooltipTitle} />}
    </Space>
);
