import { FC } from 'react';
import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';

import styles from './icon-button.module.css';

export const IconButton: FC<ButtonProps> = ({
    type = 'text',
    shape = 'circle',
    className,
    ...restProps
}) => (
    <Button
        type={type}
        shape={shape}
        className={classNames(styles.iconButton, className ?? '')}
        {...restProps}
    />
);
