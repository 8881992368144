import { CheckSignatureDocumentRequest } from '~/rtk-queries/types/certificate-verification';

type GetVerifyParams = {
    isDataSignature: boolean;
    isDetachedSignature: boolean;
    isGOST3410: boolean;
    hashFunctionSelection?: string;
};

export const getVerifyParams = ({
    isDataSignature,
    isDetachedSignature,
    isGOST3410,
    hashFunctionSelection,
}: GetVerifyParams): CheckSignatureDocumentRequest['verifyParams'] => {
    const verifyParams: CheckSignatureDocumentRequest['verifyParams'] = {
        hash: isGOST3410 ? !isDataSignature : false,
        isDetached: isDetachedSignature,
    };

    return isDataSignature
        ? verifyParams
        : { ...verifyParams, hashAlgorithm: hashFunctionSelection };
};
