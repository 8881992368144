import { Navigate } from 'react-router-dom';

import { ROUTES } from '~/constants/routes';
import { useAppSelector } from '~/hooks';
import { useCertificateAuthorization } from '~/hooks/use-certificates-authorization';
import { isAuthenticatedSelector } from '~/store/auth/auth-slice';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const isUserAuth = useAppSelector(isAuthenticatedSelector);
    const { isLoading } = useCertificateAuthorization();

    if (isLoading) {
        return null;
    }

    return isUserAuth ? children : <Navigate to={ROUTES.startPage} replace={true} />;
};
