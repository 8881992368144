import { FC, memo, useEffect } from 'react';
import { Form, FormInstance } from 'antd';
import { useWatch } from 'antd/es/form/Form';

import { SectionContainer } from './section-container/section-container';

import {
    DOCUMENT_NUMBER_LABEL,
    DocumentSectionKey,
    FormSection,
    FormSectionLabel,
    IdentityDocumentType,
    InputType,
} from '~/constants/application-tab/certificate-form';
import {
    DocumentSectionInput,
    DocumentTypeValidation,
    ResetDocumentName,
} from '~/constants/application-tab/validation';
import { useGetDocumentRules } from '~/hooks/application-tab/use-get-document-rules';
import { CreateUserInfo } from '~/rtk-queries/types/certificates';
import { IdentityDocument } from '~/rtk-queries/types/user-info';
import { CustomDataPicker } from '~/shared/form-components/custom-data-picker';
import { CustomMaskInput } from '~/shared/form-components/custom-mask-input';
import { CustomSelect } from '~/shared/form-components/custom-select';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { updateDocumentSection } from '~/utils/application-tab/update-document-section';

type DocumentsSectionProps = {
    form: FormInstance<CertificateForm>;
    draft: CreateUserInfo | null;
    data?: IdentityDocument[] | undefined;
    formDisabled?: boolean;
};

const DocumentSectionkRaw: FC<DocumentsSectionProps> = ({
    data,
    draft,
    form,
    formDisabled = false,
}) => {
    const documentType = useWatch<IdentityDocumentType | undefined>(
        DocumentTypeValidation.name,
        form,
    );

    const { rulesSetup } = useGetDocumentRules(data, documentType);

    useEffect(() => {
        if (!documentType) {
            return;
        }

        const newDocument = updateDocumentSection(documentType, draft, data);

        form.resetFields(ResetDocumentName);
        form.setFieldsValue({
            ...form.getFieldsValue(),
            document: newDocument,
        });
    }, [documentType, form, data, draft]);

    return (
        <SectionContainer label={FormSectionLabel.documents}>
            <CustomSelect
                {...DocumentTypeValidation}
                key={DocumentTypeValidation.name[1]}
                disabled={formDisabled}
            />

            <Form.Item
                name={[FormSection.document, DocumentSectionKey.id]}
                style={{ display: 'none' }}
            >
                <input />
            </Form.Item>

            {!!documentType &&
                DocumentSectionInput[documentType].map(({ name, label, type, mask }) => {
                    switch (type) {
                        case InputType.calendar:
                            return (
                                <CustomDataPicker
                                    disabled={rulesSetup[name[1]]?.disabled ?? false}
                                    rules={rulesSetup[name[1]]?.rules ?? []}
                                    key={name.toString()}
                                    {...{ label, mask, name }}
                                />
                            );
                        default:
                            return (
                                <CustomMaskInput
                                    label={
                                        name[1] === DocumentSectionKey.number &&
                                        documentType > IdentityDocumentType.FidDoc
                                            ? DOCUMENT_NUMBER_LABEL
                                            : label
                                    }
                                    key={name.toString()}
                                    disabled={rulesSetup[name[1]]?.disabled ?? false}
                                    rules={rulesSetup[name[1]]?.rules ?? []}
                                    mask={mask[documentType - 1]}
                                    {...{ name }}
                                />
                            );
                    }
                })}
        </SectionContainer>
    );
};

export const DocumentsSection = memo(DocumentSectionkRaw);
