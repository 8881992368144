export const ENTER = {
    title: 'Вход',
    titleCertificate: 'Вход по электронной подписи',
    pleaseWait: [
        'Пожалуйста, подождите.',
        'Подключаемся к модулю работы с электронной подписью....',
    ],
    installExtensionTitle: {
        text: 'Для работы со средством электронной подписи активируйте или установите расширение для браузера. Нажмите на кнопку «Установить расширение» или перейдите по',
        linkText: 'ссылке',
        additionalInfo: 'Пожалуйста, перезагрузите страницу после установки.',
    },
    installCsp: {
        text: 'Для работы со средством электронной подписи установите «КриптоПро CSP». Для того, чтобы скачать «КриптоПро CSP» перейдите по',
        linkText: 'ссылке',
        additionalInfo: 'Пожалуйста, перезагрузите страницу после установки.',
    },
    downloadPluginTitle: {
        text: 'Расширение установлено и активировано. Для работы со средством электронной подписи установите плагин. Нажмите на кнопку «Загрузить плагин» или перейдите по',
        linkText: 'ссылке',
        additionalInfo: 'Пожалуйста, перезагрузите страницу после установки.',
    },
    readInstructions: {
        text: 'Не получается войти? Ознакомьтесь с',
        linkText: 'инструкцией',
        link: 'https://www.cryptopro.ru/support/docs',
    },
    connectElectronicSignature: {
        text: 'Подключите носитель электронной подписи',
    },
    loadingModalText: 'Подождите, пожалуйста. Происходит поиск и обращение к электронной подписи',
    accessConfirmationModal: {
        title: 'Подтверждение доступа',
        text: [
            'Этот веб-сайт пытается выполнить операцию с ключами или сертификатами от имени пользователя.',
            'Выполнение таких операций следует разрешать только для веб-сайтов, которым Вы доверяете. Разрешить эту операцию?',
        ],
    },
    selectCertificate: {
        text: 'Выберите сертификат для входа',
        updateText:
            'Если сертификата нет в списке, подключите другой носитель и нажмите кнопку "Обновить"',
        empty: 'Сертификаты не обнаружены. Проверьте наличие сертификатов.',
    },
    enterPassword: {
        title: 'Ввод пароля',
        subTitle: 'Введите пароль для своего сертификата электронной подписи:',
    },
};
