import { FormInstance } from 'antd';

import { ValidateErrorEntity } from '~/types/common';

export const handleFormError = <T>(form: FormInstance<T>, error: unknown) => {
    const errorObj = error as ValidateErrorEntity<T>;

    if (errorObj?.errorFields?.length > 0) {
        form.scrollToField(errorObj.errorFields[0].name[0] as string, {
            behavior: 'smooth',
            block: 'center',
        });
    }
};
