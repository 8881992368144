import { Link } from 'react-router-dom';
import { Typography } from 'antd';

import styles from './text-link-end.module.css';

export type TitleTextLinkEndType = {
    text: string;
    additionalInfo?: string;
    linkText?: string;
    link?: string;
};

type TextLinkEndType = {
    screenTitle: TitleTextLinkEndType;
    linkInstall?: string;
    className?: string;
};

export const TextLinkEnd = ({ screenTitle, linkInstall, className }: TextLinkEndType) => (
    <Typography className={className}>
        {screenTitle.text}{' '}
        {screenTitle.linkText && (linkInstall || screenTitle.link) && (
            <Link to={screenTitle.link || (linkInstall ?? '')} target='_blank'>
                {screenTitle.linkText}
            </Link>
        )}
        {screenTitle.additionalInfo && <p className={styles.bold}>{screenTitle.additionalInfo}</p>}
    </Typography>
);
