import { Typography } from 'antd';

import styles from './documents.module.css';

import { PRINT_DOCUMENTS_ACTION, PrintTemplateType } from '~/constants/certificates';
import { Certificate } from '~/utils/transform-certificates-data/type';

type DocumentsProps = {
    variant: PrintTemplateType;
    item: Certificate;
    printHandler: (templateId: PrintTemplateType, data: Certificate) => void;
};

export const Documents = ({ variant, item, printHandler }: DocumentsProps) => (
    <Typography.Link onClick={() => printHandler(variant, item)} className={styles.certDocuments}>
        {PRINT_DOCUMENTS_ACTION[variant]}
    </Typography.Link>
);
