import { FC } from 'react';
import { FormInstance } from 'antd';

import { SectionContainer } from './section-container/section-container';

import { FormSection, FormSectionLabel } from '~/constants/application-tab/certificate-form';
import { FormOtherSection } from '~/rtk-queries/types/user-info';
import { CustomInput } from '~/shared/form-components/custom-input';
import { getFormSectionFields } from '~/utils/application-tab/get-form-section-fields';

type OtherSectionProps = {
    form: FormInstance;
    data?: FormOtherSection;
};

export const OtherSection: FC<OtherSectionProps> = ({ data, form }) => {
    if (!data) {
        return null;
    }

    return (
        <SectionContainer label={FormSectionLabel.other}>
            {getFormSectionFields(FormSection.other, data).map(
                ({ name, label, disabled, rules }) => {
                    const currentValue = form.getFieldValue(name);

                    return (
                        <CustomInput
                            key={name}
                            {...{ name, label, rules, disabled, currentValue }}
                        />
                    );
                },
            )}
        </SectionContainer>
    );
};
