import { useCallback, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import { useGetAccessToken } from './use-get-access-token';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ALTER_AUTH_DESCRIPTION, AuthModalError, ModalTextVariant } from '~/constants/modal';
import {
    useAuthByCertMutation,
    useEnsureSessionQuery,
} from '~/rtk-queries/endpoints/authentication';
import { LoginByCertificateResponse } from '~/rtk-queries/types/auth';
import { CommonResponse } from '~/types/common';
import { getLocalStorageItem } from '~/utils/local-storage';
import { signAuthRequest } from '~/utils/plugin/sign-auth-request';

export const useCertificateAuthorization = () => {
    const [isOpenAnswerModal, setIsOpenAnswerModal] = useState(false);
    const [answerForModal, setAnswerForModal] = useState({ text: '', title: '', isError: false });
    const [isLoading, setIsLoading] = useState(true);
    const [isDisabledCards, setIsDisabledCards] = useState(false);

    useGetAccessToken(setIsLoading);

    const refreshToken = getLocalStorageItem(LOCAL_STORAGE.refreshToken);

    const [authByCert, { isError: isErrorInAuthByCert, isLoading: isLoadingInAuthByCert, error }] =
        useAuthByCertMutation();

    const { data: sessionData } = useEnsureSessionQuery(refreshToken ? skipToken : {});

    const authorizationByCertificateHandler = useCallback(
        async (thumbprint: string) => {
            try {
                setIsDisabledCards(true);
                const signedContent = await signAuthRequest(
                    sessionData?.base64KeyString || '',
                    thumbprint,
                );

                if (signedContent.isError) {
                    setIsDisabledCards(false);
                    setAnswerForModal({
                        text: signedContent.text,
                        title: signedContent.title,
                        isError: true,
                    });
                    setIsOpenAnswerModal(true);
                } else {
                    authByCert({
                        sessionId: sessionData?.sessionId || 0,
                        signedContent: signedContent.text,
                        thumbprint,
                    });
                    localStorage.setItem(LOCAL_STORAGE.thumbprint, thumbprint);
                    setIsDisabledCards(false);
                }
            } catch (err: unknown) {
                console.error(err);
            }
        },
        [authByCert, sessionData?.base64KeyString, sessionData?.sessionId],
    );

    useEffect(() => {
        if (isErrorInAuthByCert && error) {
            const message = (
                error as CommonResponse<LoginByCertificateResponse>
            ).message?.toLocaleLowerCase();

            type ErrorInfo = {
                title: string;
                text: string;
            };

            let errorInfo: ErrorInfo = {
                title: ModalTextVariant.error,
                text: ModalTextVariant.tryAgain,
            };

            AuthModalError.forEach(({ errorPattern, title }) => {
                if (message?.includes(errorPattern)) {
                    errorInfo = { title, text: ALTER_AUTH_DESCRIPTION };
                }
            });

            setAnswerForModal({
                title: errorInfo.title,
                text: errorInfo.text,
                isError: true,
            });
            setIsOpenAnswerModal(true);
        }
    }, [error, isErrorInAuthByCert]);

    return {
        isLoading,
        authorizationByCertificateHandler,
        isDisabledCards,
        isLoadingInAuthByCert,
        isErrorInAuthByCert,
        isOpenAnswerModal,
        setIsOpenAnswerModal,
        answerForModal,
        setAnswerForModal,
    };
};
