import React from 'react';
import { Tooltip } from 'antd';

type CustomTooltipProps = {
    title: string;
    children: React.ReactNode;
};

export const CustomTooltip = ({ title, children }: CustomTooltipProps) => (
    <Tooltip
        title={title}
        arrow={false}
        placement='bottom'
        color='var(--white)'
        overlayInnerStyle={{ color: 'rgb(84, 84, 84)' }}
    >
        {children}
    </Tooltip>
);
