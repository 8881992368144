import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { EndpointDefinitions } from '@reduxjs/toolkit/dist/query';
import { CombinedState } from '@reduxjs/toolkit/dist/query/core/apiState';

import { appTabReducer, appTabSlice, AppTabState } from './app-tab/app-tab-slice';
import { appReducer, appSlice, AppState } from './auth/auth-slice';
import { commonReducer, commonSlice, CommonState } from './common/common-slice';
import { draftReducer, draftSlice, DraftState } from './draft/get-draft-slice';

import { interceptor } from '~/rtk-queries/interceptor';
import { popupAlertsMiddleware } from '~/rtk-queries/middleware/popup-alerts-middleware';

export type ApplicationState = Readonly<{
    [appSlice.name]: AppState;
    [appTabSlice.name]: AppTabState;
    [draftSlice.name]: DraftState;
    [commonSlice.name]: CommonState;
    api: CombinedState<EndpointDefinitions, never, 'api'>;
}>;

const rootReducer = combineSlices({
    [appSlice.name]: appReducer,
    [appTabSlice.name]: appTabReducer,
    [draftSlice.name]: draftReducer,
    [commonSlice.name]: commonReducer,
    [interceptor.reducerPath]: interceptor.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(interceptor.middleware, popupAlertsMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
});
