import { DownloadOutlined } from '@ant-design/icons';

export enum TagType {
    Draft = 'Черновик',
    SentCheck = 'Ожидает проверки сведений',
    FoldingChecks = 'Выполняется проверка сведений',
    ErrorChecksData = 'Неуспешная проверка сведений',
    ChecksExecute = 'Успешная проверка сведений',
    VerificationIsOverdue = 'Проверка сведений просрочена',
    OperatorConfirmation = 'Необходим визит в Банк',
    ApplicationRejected = 'Запрос на выпуск сертификата отклонен',
    ApplicationFulfilled = 'Ожидание выпуска сертификата',
    Released = 'Сертификат действителен',
    Revoked = 'Сертификат отозван',
    OperatorConfirmationForRevoke = 'Запрос на отзыв рассматривается Банком',
    ApplicationForReviewRejected = 'Запрос на отзыв отклонён',
    NotYetValid = 'Сертификат еще не действителен',
    KeyExpired = 'Истёк срок действия ключа',
    Expired = 'Истёк срок действия сертификата',
    WaitingForRevocation = 'Ожидание отзыва сертификата',
}

const ActionVariantsTitle = {
    continueDraft: 'Открыть черновик',
    deleteDraft: 'Удалить',
    keyGeneration: 'Сгенерировать ключи',
    installCertificate: 'Установить сертификат',
    revokeCertificate: 'Отозвать сертификат',
    viewAnswer: 'Результаты проверок',
    sendToArchive: 'Отправить в журнал',
    repeatChecks: 'Запустить проверки',
    saveToFile: <DownloadOutlined />,
} as const;

type ActionVariantsTitleType = keyof typeof ActionVariantsTitle;

export enum ActionType {
    continueDraft = 'continueDraft',
    deleteDraft = 'deleteDraft',
    keyGeneration = 'keyGeneration',
    installCertificate = 'installCertificate',
    revokeCertificate = 'revokeCertificate',
    viewAnswer = 'viewAnswer',
    sendToArchive = 'sendToArchive',
    repeatChecks = 'repeatChecks',
    saveToFile = 'saveToFile',
}

export enum StatusCode {
    Draft = 1,
    SentForCheck = 2,
    MadeCheck = 3,
    ValidateError = 4,
    CheckComplited = 5,
    AwaitingOperatorConfirmation = 6,
    ApplicationRejected = 7,
    ApplicationCompleted = 8,
    Released = 9,
    Revoked = 10,
    OperatorConfirmationForRevoke = 11,
    ApplicationForReviewRejected = 12,
    VerificationIsOverdue = 14,
    NotYetValid = 16,
    KeyExpired = 17,
    Expired = 18,
    WaitingForRevocation = 19,
}

export const statusesRevokeInProgress = [
    StatusCode.OperatorConfirmationForRevoke,
    StatusCode.ApplicationForReviewRejected,
];

enum TagColor {
    draft = '#ade4f980',
    waiting = '#ffebad80',
    success = '#c1ffc180',
    error = '#ffa6a680',
}

enum TagBorderColor {
    draft = '#ade4f9',
    waiting = '#ffebad',
    success = '#c1ffc1',
    error = '#ffa6a6',
}

const TAG_STYLE = {
    draft: { bgColor: TagColor.draft, borderColor: TagBorderColor.draft },
    waiting: { bgColor: TagColor.waiting, borderColor: TagBorderColor.waiting },
    success: { bgColor: TagColor.success, borderColor: TagBorderColor.success },
    error: { bgColor: TagColor.error, borderColor: TagBorderColor.error },
};

export enum PrintTemplateType {
    Certificate = 0,
    CertRequest = 1,
    RevokeRequest = 2,
    User = 3,
    Header = 4,
}

enum PrintTemplateLabel {
    User = 'Регистрация пользователя',
    CertRequest = 'Изготовление сертификата',
    Certificate = 'Данные сертификата',
    RevokeRequest = 'Отзыв сертификата',
}

export const PRINT_DOCUMENTS_ACTION = {
    [PrintTemplateType.User]: PrintTemplateLabel.User,
    [PrintTemplateType.CertRequest]: PrintTemplateLabel.CertRequest,
    [PrintTemplateType.Certificate]: PrintTemplateLabel.Certificate,
    [PrintTemplateType.RevokeRequest]: PrintTemplateLabel.RevokeRequest,
    [PrintTemplateType.Header]: PrintTemplateLabel.Certificate,
};

export type TagColorType = {
    bgColor: TagColor;
    borderColor: TagBorderColor;
};

type CertificateInfoType = Record<
    StatusCode,
    {
        titleStatus: TagType;
        color: TagColorType;
        actions: ActionType[];
        documents: PrintTemplateType[];
    }
>;

export const CertificatesStatusInfo: CertificateInfoType = {
    [StatusCode.Draft]: {
        titleStatus: TagType.Draft,
        color: TAG_STYLE.draft,
        actions: [ActionType.continueDraft, ActionType.deleteDraft],
        documents: [],
    },
    [StatusCode.SentForCheck]: {
        titleStatus: TagType.SentCheck,
        color: TAG_STYLE.waiting,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.User],
    },
    [StatusCode.MadeCheck]: {
        titleStatus: TagType.FoldingChecks,
        color: TAG_STYLE.waiting,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.User],
    },
    [StatusCode.ValidateError]: {
        titleStatus: TagType.ErrorChecksData,
        color: TAG_STYLE.error,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.User],
    },
    [StatusCode.CheckComplited]: {
        titleStatus: TagType.ChecksExecute,
        color: TAG_STYLE.success,
        actions: [ActionType.keyGeneration, ActionType.sendToArchive],
        documents: [PrintTemplateType.User],
    },
    [StatusCode.AwaitingOperatorConfirmation]: {
        titleStatus: TagType.OperatorConfirmation,
        color: TAG_STYLE.success,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.CertRequest],
    },
    [StatusCode.ApplicationRejected]: {
        titleStatus: TagType.ApplicationRejected,
        color: TAG_STYLE.error,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.CertRequest],
    },
    [StatusCode.ApplicationCompleted]: {
        titleStatus: TagType.ApplicationFulfilled,
        color: TAG_STYLE.waiting,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.CertRequest],
    },
    [StatusCode.Released]: {
        titleStatus: TagType.Released,
        color: TAG_STYLE.success,
        actions: [
            ActionType.installCertificate,
            ActionType.revokeCertificate,
            ActionType.saveToFile,
        ],
        documents: [PrintTemplateType.Certificate],
    },
    [StatusCode.Revoked]: {
        titleStatus: TagType.Revoked,
        color: TAG_STYLE.error,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.Certificate, PrintTemplateType.RevokeRequest],
    },
    [StatusCode.OperatorConfirmationForRevoke]: {
        titleStatus: TagType.OperatorConfirmationForRevoke,
        color: TAG_STYLE.waiting,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.Certificate, PrintTemplateType.RevokeRequest],
    },
    [StatusCode.ApplicationForReviewRejected]: {
        titleStatus: TagType.ApplicationForReviewRejected,
        color: TAG_STYLE.error,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.Certificate, PrintTemplateType.RevokeRequest],
    },
    [StatusCode.VerificationIsOverdue]: {
        titleStatus: TagType.VerificationIsOverdue,
        color: TAG_STYLE.error,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.User],
    },
    [StatusCode.NotYetValid]: {
        titleStatus: TagType.NotYetValid,
        color: TAG_STYLE.waiting,
        actions: [
            ActionType.installCertificate,
            ActionType.revokeCertificate,
            ActionType.saveToFile,
        ],
        documents: [PrintTemplateType.Certificate],
    },
    [StatusCode.KeyExpired]: {
        titleStatus: TagType.KeyExpired,
        color: TAG_STYLE.error,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.Certificate],
    },
    [StatusCode.Expired]: {
        titleStatus: TagType.Expired,
        color: TAG_STYLE.error,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.Certificate],
    },
    [StatusCode.WaitingForRevocation]: {
        titleStatus: TagType.WaitingForRevocation,
        color: TAG_STYLE.waiting,
        actions: [ActionType.sendToArchive],
        documents: [PrintTemplateType.Certificate, PrintTemplateType.RevokeRequest],
    },
};

export const CertificateActions: Record<
    ActionType,
    (typeof ActionVariantsTitle)[ActionVariantsTitleType]
> = {
    [ActionType.continueDraft]: ActionVariantsTitle.continueDraft,
    [ActionType.deleteDraft]: ActionVariantsTitle.deleteDraft,
    [ActionType.keyGeneration]: ActionVariantsTitle.keyGeneration,
    [ActionType.installCertificate]: ActionVariantsTitle.installCertificate,
    [ActionType.revokeCertificate]: ActionVariantsTitle.revokeCertificate,
    [ActionType.viewAnswer]: ActionVariantsTitle.viewAnswer,
    [ActionType.sendToArchive]: ActionVariantsTitle.sendToArchive,
    [ActionType.repeatChecks]: ActionVariantsTitle.repeatChecks,
    [ActionType.saveToFile]: ActionVariantsTitle.saveToFile,
};
