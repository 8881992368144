import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '~/hooks';
import { isAuthenticatedSelector } from '~/store/auth/auth-slice';

type IsUserAlreadyAuthPropsType = {
    redirectPath: string;
};

export const IsUserAlreadyAuth: FC<PropsWithChildren<IsUserAlreadyAuthPropsType>> = ({
    children,
    redirectPath,
}) => {
    const isUserAuth = useAppSelector(isAuthenticatedSelector);

    return isUserAuth ? <Navigate to={redirectPath} replace={true} /> : children;
};
