import { useMemo } from 'react';

import { IdentityDocumentType } from '~/constants/application-tab/certificate-form';
import { DocumentSectionValidation } from '~/constants/application-tab/validation';
import { AuthType } from '~/constants/auth-type';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { IdentityDocument, UserInfoDataValue } from '~/rtk-queries/types/user-info';
import { DocumentValidationKey, RulesSetup } from '~/types/application-tab/certificate-form';
import { createDocumentRules } from '~/utils/application-tab/create-document-rules';

export const useGetDocumentRules = (
    data?: IdentityDocument[],
    documentType?: IdentityDocumentType,
) => {
    const rulesSetup = useMemo(() => {
        const currentDocument = data?.find(
            ({ type }) => type?.value && Number(type?.value) === documentType,
        );
        const defaultRules = DocumentSectionValidation[documentType || IdentityDocumentType.Empty];

        if (!currentDocument) {
            return createDocumentRules(defaultRules);
        }

        const { id: _id, type: _type, ...restDataDocument } = currentDocument;

        const updatedRules = {} as Record<DocumentValidationKey, RulesSetup>;

        const documentDataEntries = Object.entries(restDataDocument) as [
            DocumentValidationKey,
            UserInfoDataValue | undefined,
        ][];

        const isEsiaAuth = Number(localStorage.getItem(LOCAL_STORAGE.authType)) === AuthType.Esia;

        documentDataEntries.forEach(([key, dataInfo]) => {
            const { enabled: dataEnabled, maxLength, minLength, regex, required } = dataInfo ?? {};

            const enabled = isEsiaAuth ? false : dataEnabled;

            updatedRules[key] = {
                ...defaultRules[key],
                enabled,
                maxLength,
                minLength,
                regex,
                required,
            };
        });

        return createDocumentRules(updatedRules);
    }, [data, documentType]);

    return { rulesSetup };
};
