import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { AccessLoaderModals } from './components/access-loader-modals';

import { stepName } from '~/constants/step-name';
import { StepRedirect } from '~/hoc/step-redirect';
import { useEnterPage } from '~/hooks/use-enter-page';
import { useGetEsiaLoginQuery } from '~/rtk-queries/endpoints/authentication';
import { useGetFrontConfigQuery } from '~/rtk-queries/endpoints/front-config';
import { EnterScreen } from '~/shared/enter-screen';
import { StepParams } from '~/types/router';

type EnterProps = {
    isPluginInstalled?: boolean;
};

export const Enter: FC<EnterProps> = () => {
    const { stepName: enterStep } = useParams<StepParams>();
    const { data } = useGetEsiaLoginQuery();
    const { data: frontConfig } = useGetFrontConfigQuery();

    const {
        isOpenModalAccessConfirmation,
        onClickElectronicSignature,
        linkInstall,
        onClickCheckElectronicSignature,
        onClickDownloadPlugin,
        onClickInstallExtension,
        onClickOtherLogin,
        setIsOpenModalAccessConfirmation,
        loadCadesPlugin,
    } = useEnterPage(frontConfig);

    const authButtonHandler =
        enterStep === stepName.one && !loadCadesPlugin
            ? onClickElectronicSignature
            : onClickOtherLogin;

    return (
        <StepRedirect>
            <EnterScreen
                onClickButtonBottom={authButtonHandler}
                onClickButtonTop={
                    (enterStep === stepName.two && onClickInstallExtension) ||
                    (enterStep === stepName.three && onClickDownloadPlugin) ||
                    ((enterStep === stepName.four || enterStep === stepName.five) &&
                        onClickCheckElectronicSignature) ||
                    undefined
                }
                linkEsia={enterStep === stepName.one ? data?.esiaAuthUrl : null}
                linkInstall={linkInstall}
                frontConfig={frontConfig}
                isLoadingPlugin={loadCadesPlugin}
            />

            <AccessLoaderModals
                isOpenModalAccessConfirmation={isOpenModalAccessConfirmation}
                setIsOpenModalAccessConfirmation={setIsOpenModalAccessConfirmation}
            />
        </StepRedirect>
    );
};
