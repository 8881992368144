import { sortStrings } from '../sort-string';

import { Country } from '~/rtk-queries/types/user-info';

const convertDataCountry = (id: string, shortName: string) => ({
    value: id.toString(),
    label: shortName,
});

export const optionCountry = (countries: Country[]) => {
    const countriesOptions = countries?.map(({ id, shortName }) =>
        convertDataCountry(id, shortName),
    );

    countriesOptions?.push(convertDataCountry('0', ' '));

    return countriesOptions?.sort((a, b) => sortStrings(a.label, b.label));
};
