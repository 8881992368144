import { useEffect, useLayoutEffect, useState } from 'react';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { useGetTemplateQuery } from '~/rtk-queries/endpoints/template';
import { parseTemplateString } from '~/utils/application-tab/parse-template-string';
import { getLocalStorageItem } from '~/utils/local-storage';

type TemplateSetup = {
    folderId: number;
    templateId: number;
};

export const useGetTemplateData = () => {
    const storedDraftData = getLocalStorageItem(LOCAL_STORAGE.draftData);

    const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>();
    const [selectedTemplateName, setSelectedTemplateName] = useState('');

    const [templateSetup, setTemplateSetup] = useState<TemplateSetup>({
        folderId: storedDraftData ? Number(JSON.parse(storedDraftData).folderId) : 0,
        templateId: storedDraftData ? Number(JSON.parse(storedDraftData).templateId) : 0,
    });

    const changeTemplate = (template: string) => setSelectedTemplate(template);

    const { data: templatesData, isFetching: isFetchingTemplate } = useGetTemplateQuery({});

    const resetTemplate = () => setSelectedTemplate(undefined);

    // задаем папку и шаблон в зависимости от черновик/новый документ с шаблоном
    useLayoutEffect(() => {
        if (storedDraftData) {
            setTemplateSetup({
                folderId: Number(JSON.parse(storedDraftData).folderId),
                templateId: Number(JSON.parse(storedDraftData).templateId),
            });
        } else {
            const templateInfo = parseTemplateString(selectedTemplate);

            setTemplateSetup({
                folderId: templateInfo.folderId ?? 0,
                templateId: templateInfo.templateId ?? 0,
            });
        }
    }, [selectedTemplate, storedDraftData]);

    useEffect(() => {
        const { folderId, templateId } = templateSetup;

        if (templateId && folderId) {
            const foundTemplate = templatesData?.templates.find(
                (elem) => elem.id === Number(templateId) && elem.folderId === Number(folderId),
            );

            setSelectedTemplateName(foundTemplate?.displayName || '');
        }
    }, [templateSetup, templatesData]);

    return {
        templateSetup,
        selectedTemplateName,
        selectedTemplate,
        changeTemplate,
        resetTemplate,
        isFetchingTemplate,
    };
};
