import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';

import styles from './app.module.css';

import { AppRouter } from '~/app-router';

export const App = () => {
    useEffect(() => {
        window.process = {
            ...window.process,
            nextTick: () => {},
        };
    }, []);

    return (
        <div className={styles.content}>
            <ConfigProvider locale={ru_RU}>
                <RouterProvider router={AppRouter} />
            </ConfigProvider>
        </div>
    );
};
