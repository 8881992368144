import { FC } from 'react';
import { Card, Table } from 'antd';

import { returnArrayForTable } from '~/components/check-and-sign-certificate/content/check-document/result-block/main-info/constants/return-array-for-table';
import { returnColumns } from '~/components/check-and-sign-certificate/content/check-document/result-block/main-info/constants/return-columns';

export type MainInfoPropsType = { fileName: string; result: boolean; message?: string };

export const MainInfo: FC<MainInfoPropsType> = ({ ...restProps }) => (
    <Card>
        <Table
            dataSource={returnArrayForTable(restProps).filter((el) => el.columnResult)}
            columns={returnColumns(restProps.result)}
            pagination={false}
            showHeader={false}
            scroll={{ x: 200 }}
        />
    </Card>
);
