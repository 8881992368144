import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons/lib/components/Icon';

const UnlockIconSvg = () => (
    <svg width='20' height='23' viewBox='0 0 20 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.94336 2.64597C10.8651 2.64561 11.882 3.18231 12.4869 3.57457L12.4881 6.64602L14.6698 6.64517L14.6685 3.35864C14.6683 2.81448 14.4205 2.25667 13.9193 1.90871C13.3259 1.49674 11.6957 0.463672 9.94251 0.464356C8.19096 0.465039 6.56098 1.49564 5.9668 1.90727C5.46453 2.25522 5.2161 2.81391 5.2161 3.35902V7.73707H1.94335H0.852539V8.82788V21.9176V23.0084H1.94335H17.9419H19.0327V21.9176V8.82788V7.73707H17.9419H7.39771V3.57441C8.00334 3.18253 9.02109 2.64633 9.94336 2.64597ZM3.03415 9.91868V20.8268H16.851V9.91868H3.03415ZM8.48778 12.4634H10.6694V17.5538H8.48778V12.4634Z'
            fill='#3E3E59'
        />
    </svg>
);

export const UnlockIconComponent = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={UnlockIconSvg} {...props} />
);
