import { FC, memo, MouseEventHandler } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Typography } from 'antd';

import { getDataForRender } from '../../utils/get-data-for-render';

import styles from '~/shared/enter-screen/enter-screen.module.css';

import { BUTTON_TEXT, ButtonType } from '~/constants/button';
import { ENTER } from '~/constants/enter';
import { stepName } from '~/constants/step-name';
import { CustomButton } from '~/shared/custom-button';
import { StepParams } from '~/types/router';

type ButtonsBlockProps = {
    updateCertificates: () => Promise<void>;
    onClickButtonBottom: MouseEventHandler<HTMLElement>;
    isLoadingInAuthByCert?: boolean;
    isFetchingCertificates?: boolean;
    isLoadingPlugin?: boolean;
};

export const ButtonsBlock: FC<ButtonsBlockProps> = memo(
    ({
        isLoadingInAuthByCert,
        onClickButtonBottom,
        updateCertificates,
        isFetchingCertificates,
        isLoadingPlugin,
    }) => {
        const { stepName: currentStep } = useParams<StepParams>();

        const { buttonTypeBottom, titleBottom } = getDataForRender(currentStep);

        const isFinalScreen = currentStep === stepName.five;
        const isOtherAuthVariantButton = buttonTypeBottom !== ButtonType.link && !!buttonTypeBottom;
        const isPrimaryBtn = currentStep === stepName.five || currentStep === stepName.four;
        const buttonType = isPrimaryBtn ? 'primary' : 'default';
        const buttonClassName = isPrimaryBtn ? 'enterPageButton' : 'updatePageButton';
        const isLoadingEnviroment = isLoadingPlugin && currentStep === stepName.one;
        const buttonText = isLoadingEnviroment ? BUTTON_TEXT.loadPlugin : titleBottom;

        return (
            <Flex vertical={true} gap='20px'>
                {isFinalScreen && (
                    <Flex vertical={true} gap='8px'>
                        {!isFetchingCertificates && !isLoadingInAuthByCert && (
                            <Typography className={styles.updateText}>
                                {ENTER.selectCertificate.updateText}
                            </Typography>
                        )}
                        <CustomButton
                            type={buttonType}
                            className={buttonClassName}
                            onClick={updateCertificates}
                            disabled={isLoadingInAuthByCert || isFetchingCertificates}
                        >
                            {BUTTON_TEXT.update}
                        </CustomButton>
                    </Flex>
                )}
                {isOtherAuthVariantButton && (
                    <CustomButton
                        htmlType={buttonTypeBottom ?? ButtonType.button}
                        className='updatePageButton'
                        onClick={onClickButtonBottom}
                        disabled={isLoadingInAuthByCert || isLoadingEnviroment}
                        loading={isLoadingEnviroment}
                    >
                        {buttonText}
                    </CustomButton>
                )}
            </Flex>
        );
    },
);
