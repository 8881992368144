import { Space, SpaceProps, Typography } from 'antd';
import classnames from 'classnames';

import styles from './certificates-title.module.css';

type CertificatesTitleProps = SpaceProps & {
    title: string;
    needColorSelection?: boolean;
};

const SERIAL_NUMBER_TITLE = 'Серийный номер';

export const CertificatesTitle = ({
    title,
    needColorSelection = false,
    ...spaceProps
}: CertificatesTitleProps) => {
    const titleIncludeSerialNumber = title && title.includes(SERIAL_NUMBER_TITLE);

    const titleRender = titleIncludeSerialNumber ? title.split(SERIAL_NUMBER_TITLE) : title;

    return (
        <Space {...spaceProps}>
            {titleIncludeSerialNumber ? (
                <Space className={styles.certificatesTitle}>
                    <Typography.Text
                        className={classnames({ [styles.textTitle]: needColorSelection })}
                    >
                        {titleRender[0]}
                    </Typography.Text>
                    <Typography.Text className={styles.certificatesText}>
                        <span className={classnames({ [styles.coloredText]: needColorSelection })}>
                            {SERIAL_NUMBER_TITLE}
                        </span>
                        <span className={classnames({ [styles.textNote]: needColorSelection })}>
                            {titleRender[1]}
                        </span>
                    </Typography.Text>
                </Space>
            ) : (
                <Typography.Text>{titleRender}</Typography.Text>
            )}
        </Space>
    );
};
