import { FC, PropsWithChildren } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, TooltipProps } from 'antd';
import classnames from 'classnames';

import styles from './file-upload-tooltip.module.css';

export const FileUploadTooltip: FC<PropsWithChildren<TooltipProps>> = ({
    children,
    ...tooltipProps
}) => (
    <Tooltip
        overlayClassName={classnames(tooltipProps.overlayClassName, styles.centered)}
        {...tooltipProps}
    >
        <InfoCircleOutlined className={styles.icon} />
        {children}
    </Tooltip>
);
