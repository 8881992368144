import { Signature } from '../types/signature';

import { FileExtension, SignatureDescription } from '~/rtk-queries/types/certificate-verification';

export const returnSignatureType = (
    fileName: string,
    signatureDescriptions: Array<SignatureDescription>,
) => {
    const fileExtension = fileName.split('.').pop() as FileExtension[number];
    const currentSignatureType = signatureDescriptions.find(({ fileExtensions }) =>
        fileExtensions.includes(fileExtension),
    );

    return currentSignatureType ? currentSignatureType?.signatureType : Signature.CMS;
};
