import { FC, memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';

import styles from './templates-select.module.css';

import { AccountTab } from '~/constants/account-tab';
import { FormPlaceholders } from '~/constants/application-tab/general';
import { BUTTON_TEXT } from '~/constants/button';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { WithLoader } from '~/hoc/with-loader';
import { useGetTemplateQuery } from '~/rtk-queries/endpoints/template';
import { CustomButton } from '~/shared/custom-button';
import { OptionType } from '~/types/common';
import { parseTemplateString } from '~/utils/application-tab/parse-template-string';
import { getTemplateOptions } from '~/utils/get-template-options';
import { getLocalStorageItem } from '~/utils/local-storage';

type TemplatesSelectProps = {
    onClickContinue: () => void;
    changeTemplate: (template: string) => void;
    isLoading: boolean;
    selectedTemplate?: string;
};

const TemplatesSelectRaw: FC<TemplatesSelectProps> = ({
    onClickContinue,
    selectedTemplate,
    changeTemplate,
    isLoading,
}) => {
    const { description } = parseTemplateString(selectedTemplate);
    const { data: templatesData } = useGetTemplateQuery({});
    const { tabName } = useParams();
    const [optionsTemplates, setOptionsTemplates] = useState<OptionType[]>([]);
    const storedDraftData = getLocalStorageItem(LOCAL_STORAGE.draftData);

    useEffect(() => {
        if (tabName === AccountTab.applicationTab && !storedDraftData) {
            const options = getTemplateOptions(templatesData?.templates);

            setOptionsTemplates(options);
        }
    }, [storedDraftData, tabName, templatesData?.templates]);

    return (
        <WithLoader isLoading={isLoading} className={styles.spinner}>
            <div className={styles.applicationTabBlocks}>
                <div className={styles.certificateTemplate}>
                    <Select
                        className={styles.templateSelect}
                        options={optionsTemplates}
                        placeholder={FormPlaceholders.CertificateTemplateSelect}
                        onChange={changeTemplate}
                        value={selectedTemplate}
                    />
                </div>
                <div>{description ?? ''}</div>
                <CustomButton
                    className='applicationTabButton'
                    onClick={onClickContinue}
                    disabled={!selectedTemplate}
                >
                    {BUTTON_TEXT.continue}
                </CustomButton>
            </div>
        </WithLoader>
    );
};

export const TemplatesSelect = memo(TemplatesSelectRaw);
