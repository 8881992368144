import { MainInfoPropsType } from '~/components/check-and-sign-certificate/content/check-certificate/result-block/main-info/main-info';

export const COLUMN_NAME = {
    resultOfChecking: 'Результат проверки',
    documentName: 'Название документа',
    additionalInformation: 'Дополнительная информация',
};

export const returnArrayForTable = ({ fileName, result, message }: MainInfoPropsType) => [
    {
        columnName: COLUMN_NAME.resultOfChecking,
        columnResult: result ? 'Сертификат прошел проверку' : 'Сертификат не прошел проверку',
    },
    {
        columnName: COLUMN_NAME.documentName,
        columnResult: fileName,
    },
    { columnName: COLUMN_NAME.additionalInformation, columnResult: message },
];
