import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CommonState = typeof initialState;

const initialState = {
    isLoading: false,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
    },
    selectors: {
        commonRootSelector: (state) => state,
        commonIsLoadingSelector: (state) => state.isLoading,
    },
});

export const { setIsLoading } = commonSlice.actions;

export const { commonIsLoadingSelector, commonRootSelector } = commonSlice.selectors;
export const commonReducer = commonSlice.reducer;
