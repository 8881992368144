import { convertDateTime } from '~/components/check-and-sign-certificate/content/check-certificate/helpers/convert-date-time';
import { SignatureInfo } from '~/rtk-queries/types/certificate-verification';

export const COLUMN_NAME = {
    signatureFormat: 'Формат подписи',
    signatureTime: 'Время подписи',
};

export const returnArrayForTable = ({ CAdESType, LocalSigningTimeUTC }: SignatureInfo) => [
    {
        columnName: COLUMN_NAME.signatureFormat,
        columnResult: `CAdES-${CAdESType}`,
    },
    { columnName: COLUMN_NAME.signatureTime, columnResult: convertDateTime(LocalSigningTimeUTC) },
];
