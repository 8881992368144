import { RouteObject } from 'react-router-dom';

import { loaderCryptoProPlugin } from '~/app-router/utils/loader-crypto-pro-plugin';
import { ROUTES } from '~/constants/routes';
import { IsUserAlreadyAuth } from '~/hoc/is-user-already-auth';
import { EntryPagesLayout } from '~/pages/enter/entry-pages-layout';
import { LazyEnterPage } from '~/pages/enter/lazy-enter-page';

export const stepsRoot: RouteObject = {
    path: ROUTES.stepsRootPage,
    element: (
        <IsUserAlreadyAuth redirectPath={ROUTES.privateRoom}>
            <EntryPagesLayout />
        </IsUserAlreadyAuth>
    ),
    children: [
        {
            path: ROUTES.stepPage,
            element: <LazyEnterPage />,
            loader: loaderCryptoProPlugin,
        },
    ],
};
