import { FC } from 'react';
import { FormInstance } from 'antd';

import { SectionContainer } from './section-container/section-container';

import { FormSection, FormSectionLabel } from '~/constants/application-tab/certificate-form';
import { FormBlockLabel } from '~/constants/application-tab/general';
import { useGetRegionInfoQuery } from '~/rtk-queries/endpoints/user-info';
import { CountriesResponse, FormAddressSection } from '~/rtk-queries/types/user-info';
import { CustomMaskInput } from '~/shared/form-components/custom-mask-input';
import { CustomSelect } from '~/shared/form-components/custom-select';
import { optionCountry } from '~/utils/application-tab/get-country-data';
import { getFormSectionFields } from '~/utils/application-tab/get-form-section-fields';

type AddressSectionProps = {
    form: FormInstance;
    data?: FormAddressSection;
};

export const AddressSection: FC<AddressSectionProps> = ({ data, form }) => {
    const { data: countriesData = {} as CountriesResponse } = useGetRegionInfoQuery();

    if (!data) {
        return null;
    }

    return (
        <SectionContainer label={FormSectionLabel.address}>
            {getFormSectionFields(FormSection.address, data).map(
                ({ name, label, disabled, mask, rules }) => {
                    const currentValue = form.getFieldValue(name);

                    if (label === FormBlockLabel.region) {
                        return (
                            <CustomSelect
                                key={name}
                                options={optionCountry(countriesData.countries)}
                                {...{ label, rules, mask, disabled, name }}
                            />
                        );
                    }

                    return (
                        <CustomMaskInput
                            key={name}
                            {...{ label, rules, mask, disabled, name, currentValue }}
                        />
                    );
                },
            )}
        </SectionContainer>
    );
};
