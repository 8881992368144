export const customValidator = (
    rejectCondition: boolean,
    rejectCallback?: () => void,
    resolveCallback?: () => void,
) => {
    if (rejectCondition) {
        rejectCallback?.();

        return Promise.reject();
    }
    resolveCallback?.();

    return Promise.resolve();
};
