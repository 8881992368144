import { Tags } from '../constants/tags';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { interceptor } from '../interceptor';

import { AlertMessage } from '~/constants/alert-message';
import { AuthType } from '~/constants/auth-type';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { API } from '~/rtk-queries/constants/api';
import {
    EsiaAuthUrlResponse,
    LoginByCertificateRequest,
    LoginByCertificateResponse,
    LoginByEsiaRequest,
    LoginByEsiaResponse,
    LogoutEsiaResponse,
    SessionDataResponse,
    SessionParams,
} from '~/rtk-queries/types/auth';
import { CommonResponse } from '~/types/common';

export const authentication = interceptor
    .enhanceEndpoints({
        addTagTypes: [Tags.ENSURE_SESSION, Tags.ESIA_LOGIN_URL, Tags.USER_NAME],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            ensureSession: builder.query<CommonResponse<SessionDataResponse>, SessionParams>({
                query: ({ id }) => ({
                    url: API.EnsureSession,
                    method: 'POST',
                    params: { id },
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                extraOptions: { auth: true, ensureSession: true },
                providesTags: [Tags.ENSURE_SESSION],
            }),
            getEsiaLogin: builder.query<CommonResponse<EsiaAuthUrlResponse>, void>({
                query: () => ({
                    url: API.getEsiaLoginUrl,
                    method: 'GET',
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                providesTags: [Tags.ESIA_LOGIN_URL],
            }),
            postLoginByEsia: builder.mutation<
                CommonResponse<LoginByEsiaResponse>,
                LoginByEsiaRequest
            >({
                query: (data) => ({
                    url: API.loginByEsia,
                    method: 'POST',
                    body: data,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_ESIA),
                extraOptions: { auth: true, ensureSession: false },
                async onQueryStarted(_, { queryFulfilled }) {
                    try {
                        await queryFulfilled;
                        localStorage.setItem(LOCAL_STORAGE.authType, String(AuthType.Esia));
                    } catch (_error: unknown) {
                        //
                    }
                },
                invalidatesTags: (_, error) =>
                    error ? [Tags.ENSURE_SESSION, Tags.ESIA_LOGIN_URL] : [Tags.USER_NAME],
            }),
            postLogout: builder.mutation<CommonResponse, void>({
                query: () => ({
                    url: API.logout,
                    method: 'POST',
                }),
            }),
            getLogoutEsiaUrl: builder.mutation<CommonResponse<LogoutEsiaResponse>, void>({
                query: () => ({
                    url: API.logoutEsia,
                    method: 'POST',
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),
            refreshToken: builder.mutation<
                LoginByEsiaResponse,
                { sessionId: number; refreshToken: string }
            >({
                query: (body) => ({
                    url: API.refreshToken,
                    method: 'POST',
                    body,
                }),
                extraOptions: { auth: true, ensureSession: false },
            }),
            authByCert: builder.mutation<
                CommonResponse<LoginByCertificateResponse>,
                LoginByCertificateRequest
            >({
                query: (body) => ({
                    url: API.loginByCertificate,
                    method: 'POST',
                    body,
                }),
                extraOptions: { auth: true, ensureSession: false },
                async onQueryStarted(_, { queryFulfilled }) {
                    try {
                        await queryFulfilled;
                        localStorage.setItem(LOCAL_STORAGE.authType, String(AuthType.Signature));
                    } catch (_error: unknown) {
                        //
                    }
                },
                transformErrorResponse: ({ data }) => data as LoginByCertificateResponse,
                invalidatesTags: (_, error) =>
                    error ? [Tags.ENSURE_SESSION, Tags.ESIA_LOGIN_URL] : [Tags.USER_NAME],
            }),
        }),
    });

export const {
    useEnsureSessionQuery,
    useGetEsiaLoginQuery,
    usePostLoginByEsiaMutation,
    usePostLogoutMutation,
    useGetLogoutEsiaUrlMutation,
    useAuthByCertMutation,
    useRefreshTokenMutation,
} = authentication;
