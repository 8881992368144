import { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { CollapseProps } from 'antd';

type UseCollapseProps = {
    itemsForCollapse: CollapseProps['items'];
    defaultOpeningCondition: (key?: Key) => boolean | undefined;
};

export const useCollapse = ({
    itemsForCollapse = [],
    defaultOpeningCondition,
}: UseCollapseProps) => {
    const firstRenderActiveKeys = useMemo(
        () =>
            itemsForCollapse.reduce<string[]>((acc, item) => {
                if (defaultOpeningCondition(item.key)) {
                    acc.push(item.key as string);
                }

                return acc;
            }, []),
        [itemsForCollapse, defaultOpeningCondition],
    );

    const [activeKeys, setActiveKeys] = useState<string[] | string>(firstRenderActiveKeys);

    const openCollapseHandler = useCallback((items: string | string[]) => setActiveKeys(items), []);

    useEffect(() => {
        setActiveKeys(firstRenderActiveKeys);
    }, [firstRenderActiveKeys]);

    return { activeKeys, openCollapseHandler };
};
