import { FC, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classnames from 'classnames';

import styles from './custom-enter-link.module.css';

export const CustomEnterLink: FC<PropsWithChildren<LinkProps>> = ({
    children,
    className,
    ...linkProps
}) => (
    <Link className={classnames(styles.enterLink, className)} {...linkProps}>
        {children}
    </Link>
);
