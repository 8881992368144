// import CallIcon from '../components/contact-us/assets/call-icon.svg';
import MailIcon from '../components/contact-us/assets/mail-icon.svg';
import ReviewIcon from '../components/contact-us/assets/review-icon.svg';

// import TelegramIcon from '../components/contact-us/assets/telegram-icon.svg';
// import ViberIcon from '../components/contact-us/assets/viber-icon.svg';
// import WhatsAppIcon from '../components/contact-us/assets/whats-app-icon.svg';
import { FrontConfigKey } from '~/utils/get-front-config/type';

export enum ContactUsTitle {
    Viber = 'Написать в Viber',
    Telegram = 'Написать в Telegram',
    WhatsApp = 'Написать в WhatsApp',
    Mail = 'Письмо в банк',
    Call = 'Онлайн звонок',
    Review = 'Отзыв',
}

export enum ContactUsLink {
    Viber = 'https://chats.viber.com/psbot',
    Telegram = 'https://t.me/bankPSB_bot',
    WhatsApp = 'https://wa.me/79646260299',
    Mail = 'mailto:info@psbank.ru',
    Call = 'https://www.psbank.ru/call',
}

export const CONTACT_US = [
    // https://confluence.services.clevertec.ru/pages/viewpage.action?pageId=71143927
    // {
    //     title: ContactUsTitle.Viber,
    //     link: ContactUsLink.Viber,
    //     icon: ViberIcon,
    //     keyFrontConfig: FrontConfigKey.ContactLinkViber,
    // },
    // {
    //     title: ContactUsTitle.Telegram,
    //     link: ContactUsLink.Telegram,
    //     icon: TelegramIcon,
    //     keyFrontConfig: FrontConfigKey.ContactLinkTelegram,
    // },
    // {
    //     title: ContactUsTitle.WhatsApp,
    //     link: ContactUsLink.WhatsApp,
    //     icon: WhatsAppIcon,
    //     keyFrontConfig: FrontConfigKey.ContactLinkWhatsApp,
    // },
    {
        title: ContactUsTitle.Mail,
        link: ContactUsLink.Mail,
        icon: MailIcon,
        keyFrontConfig: FrontConfigKey.ContactLinkMail,
    },
    // {
    //     title: ContactUsTitle.Call,
    //     link: ContactUsLink.Call,
    //     icon: CallIcon,
    //     keyFrontConfig: FrontConfigKey.ContactLinkCall,
    // },
];

export const CONTACT_US_MODAL = {
    title: ContactUsTitle.Review,
    icon: ReviewIcon,
};
