import { PartialRecord } from '~/types/common';

export const filterCommontForm = <Model extends object, FieldObj extends { enabled?: boolean }>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: PartialRecord<keyof Model, any>,
    data?: PartialRecord<keyof Model, FieldObj>,
) => {
    const filteredForm = { ...form };

    (Object.keys(filteredForm) as (keyof Model)[]).forEach((key) => {
        const criteria = !data?.[key] || !data?.[key]?.enabled;

        if (criteria) {
            delete filteredForm[key];
        }
    });

    return filteredForm;
};
