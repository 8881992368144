import { getCertificateFields } from './get-certificate-fields';
import { getLastPluginError } from './get-last-plugin-error';

// Утилита для асинхронного получения данных о всех сертификатах в системе через cadesplugin Store
export const fetchAllCertificatesInSystem = async () => {
    const { cadesplugin } = window;

    try {
        // Создание асинхронного объекта CAdESCOM.Store
        const oStore = await cadesplugin.CreateObjectAsync('CAdESCOM.Store');

        // Открытие хранилища сертификатов с максимальными правами
        await oStore.Open(
            cadesplugin.CADESCOM_CONTAINER_STORE,
            cadesplugin.CAPICOM_MY_STORE,
            cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
        );

        // Получение коллекции сертификатов из хранилища
        const oCertificates = await oStore.Certificates;

        // Получение общего количества сертификатов в хранилище
        const count = await oCertificates.Count;

        // Получение массива промисов для каждого сертификата
        const certPromises = Array.from({ length: count }, (_, i) => oCertificates.Item(i + 1));

        // Ожидание выполнения всех промисов для получения массива сертификатов
        const certificatesInUserSystem = await Promise.all(certPromises);

        // Получение массива промисов для получения данных о каждом сертификате
        const certificatesDataPromises = certificatesInUserSystem.map(getCertificateFields);

        // Ожидание выполнения всех промисов для получения данных о каждом сертификате
        const allCertificatesData = await Promise.all(certificatesDataPromises);

        // Возврат массива данных о всех сертификатах в системе
        return allCertificatesData;
    } catch (e) {
        return getLastPluginError(e);

        // Обработка возможных ошибок и возвращение null в случае их возникновения
    }
};
