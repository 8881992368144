import { FC } from 'react';

import styles from './animated-ellipsis.module.css';

type AnimatedEllipsisProps = {
    title: string;
};

export const AnimatedEllipsis: FC<AnimatedEllipsisProps> = ({ title }) => (
    <div className={styles.ellipsisAnimated}>
        {title}
        <span>.</span>
        <span>.</span>
        <span>.</span>
    </div>
);
