import { FrontConfigKey, FrontConfigType } from './get-front-config/type';

type DataContactUsType = {
    title: string;
    link: string;
    icon: string;
};

export type ConvertDateToStringType = {
    frontConfig?: FrontConfigType;
    dataContactUs: (DataContactUsType & { keyFrontConfig: FrontConfigKey })[];
};

export const convertFrontConfigLinkToContactUs = ({
    frontConfig,
    dataContactUs,
}: ConvertDateToStringType) => {
    const convertArr = [] as DataContactUsType[];

    dataContactUs.forEach(
        ({
            title,
            link,
            icon,
            keyFrontConfig,
        }: DataContactUsType & { keyFrontConfig: FrontConfigKey }) =>
            convertArr.push({
                title,
                link: frontConfig ? frontConfig[keyFrontConfig] : link,
                icon,
            }),
    );

    return convertArr;
};
