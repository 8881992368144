import { FC } from 'react';
import { Spin, SpinProps } from 'antd';

import styles from './custom-spinner.module.css';

type CustomSpinnerProps = SpinProps & {
    className?: string;
};

const content = <span />;

export const CustomSpinner: FC<CustomSpinnerProps> = ({ tip = '', className, ...props }) => (
    <Spin className={styles.spinner} tip={tip} wrapperClassName={className} {...props}>
        {content}
    </Spin>
);
