import { RouteObject } from 'react-router-dom';

import { checkCertificate, checkDocument, signDocument } from './content-path';

import { ROUTES } from '~/constants/routes';
import { PrivateRoom } from '~/pages/private-room';

export const privateRoomPath: RouteObject = {
    path: ROUTES.privateRoom,
    element: <PrivateRoom />,
    children: [
        {
            path: ':tabName',
            element: <PrivateRoom />,
            children: [checkCertificate, checkDocument, signDocument],
        },
    ],
};
