import { EnrichmentStatusOut } from '~/constants/certificates-table';

export const VIEW_ANSWER = {
    viewAnswerModal: {
        title: 'Возникли следующие ошибки', // нет нужного текста
    },
};

export const EnrichmentStatusAlertType: Record<
    EnrichmentStatusOut,
    'success' | 'processing' | 'error' | 'default'
> = {
    [EnrichmentStatusOut.Pending]: 'default',
    [EnrichmentStatusOut.Success]: 'success',
    [EnrichmentStatusOut.Error]: 'error',
    [EnrichmentStatusOut.Waiting]: 'processing',
};
