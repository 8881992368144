import { createContext, Dispatch, SetStateAction } from 'react';

type LoaderContextType = {
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    startLoading: () => void;
    stopLoading: () => void;
};

const initialContext: LoaderContextType = {
    isLoading: false,
    startLoading: () => {},
    stopLoading: () => {},
    setIsLoading: () => {},
};

export const LoaderContext = createContext<LoaderContextType>(initialContext);
