import { Rule } from 'antd/es/form';

import { isBoolean } from '../is-boolean';

import { createValidationRules } from './create-validation-rules';

import { DocumentValidationKey, RulesSetup } from '~/types/application-tab/certificate-form';

type DocumentSetup = Record<DocumentValidationKey, RulesSetup>;

export const createDocumentRules = (documentSetup: DocumentSetup) => {
    const rulesEntries = Object.entries(documentSetup) as [DocumentValidationKey, RulesSetup][];

    const rulesSetup = {} as Record<DocumentValidationKey, { disabled: boolean; rules: Rule[] }>;

    rulesEntries.forEach(([key, { enabled, ...restRules }]) => {
        rulesSetup[key] = {
            disabled: isBoolean(enabled) ? !enabled : false,
            rules: createValidationRules(restRules),
        };
    });

    return rulesSetup;
};
