import { CadesPluginCertificateData } from '~/types/plugin';
import { convertSubjectToDataCertificate } from '~/utils/conver-subject-to-data-certificate';

export const returnCertificatesOptions = (certificates?: CadesPluginCertificateData[]) => {
    if (!certificates) return [];

    const optionForSelect = certificates.map((certificate) => {
        const { fullName } = convertSubjectToDataCertificate({
            certificate,
            validToDate: certificate.validToDate,
        });
        const validToDate = new Date(certificate.validToDate).toLocaleString();
        const label = `${fullName}, ${validToDate}`;

        return {
            value: certificate.signByThumbprint,
            label,
        };
    });

    return optionForSelect;
};
