import { TablePaginationConfig } from 'antd';

import { createPageSizeOptions } from './create-page-size-options';
import { showTotalFunc } from './show-total-func';

export const getPagination = (
    currentTablePage: number,
    currentTableSize: number,
    totalElements: number,
    handleChange: (page: number, pageSize: number) => void,
): TablePaginationConfig => ({
    current: currentTablePage,
    pageSize: currentTableSize ? Number(currentTableSize) : totalElements,
    total: totalElements,
    showSizeChanger: true,
    onChange: handleChange,
    onShowSizeChange: handleChange,
    pageSizeOptions: createPageSizeOptions(totalElements),
    showTotal: showTotalFunc,

    locale: {
        items_per_page: 'стр.',
    },
    position: ['bottomCenter'],
});
