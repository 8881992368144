import { useCallback } from 'react';
import { Alert } from 'antd';

import { Portal } from '../portal';

import styles from './custom-alert.module.css';

import { useAppDispatch, useAppSelector } from '~/hooks';
import { deleteFirstPopupAlert, popupAlertSelector } from '~/store/auth/auth-slice';

export const CustomAlert = () => {
    const alert = useAppSelector(popupAlertSelector);
    const dispatch = useAppDispatch();

    const handleDeletePopupAlert = useCallback(() => {
        dispatch(deleteFirstPopupAlert());
    }, [dispatch]);

    if (!alert) {
        return null;
    }

    return (
        <Portal>
            <div className={styles.alertContainer}>
                <Alert
                    key={alert?.message}
                    className={styles.alert}
                    type={alert?.type}
                    showIcon={true}
                    closable={true}
                    message={alert?.message}
                    afterClose={handleDeletePopupAlert}
                />
            </div>
        </Portal>
    );
};
