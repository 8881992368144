import { cloneElement, FC, PropsWithChildren, ReactElement, useEffect, useRef } from 'react';

import { isNotEmptyArray } from '../../../../utils/is-not-empty-array';
import { DATE_RANGE_ATTRIBUTE } from '../../constants/general';
import { addDotsToDate } from '../helpers/add-dots-to-date';
import { findChildrenByAttributeValue } from '../helpers/find-children-by-attribute-value';

export const DateContainer: FC<PropsWithChildren> = ({ children, ...props }) => {
    const element = cloneElement(children as ReactElement, { ...props });
    const inputId = 'id' in props ? (props.id as string) : null;
    const dateInputRef = useRef(null);

    useEffect(() => {
        if (dateInputRef.current && inputId) {
            const inputArray = findChildrenByAttributeValue(
                dateInputRef.current,
                DATE_RANGE_ATTRIBUTE,
            ) as HTMLInputElement[];

            if (isNotEmptyArray(inputArray)) {
                addDotsToDate(inputArray);
            }
        }
    }, [element, inputId]);

    return <div ref={dateInputRef}>{element}</div>;
};
