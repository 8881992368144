import { DocumentInputSetup } from '~/types/application-tab/certificate-form';
import { PartialRecord } from '~/types/common';

export const filterDocumentForm = <Model extends object, FieldObj extends { enabled?: boolean }>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: PartialRecord<keyof Model, any>,
    data: PartialRecord<keyof Model, FieldObj>,
    documentInput: DocumentInputSetup[],
) => {
    const filteredForm = { ...form };

    (Object.keys(filteredForm) as (keyof Model)[]).forEach((key) => {
        const criteria = data?.[key] && !data?.[key]?.enabled;

        const isActiveInputType = documentInput.find(
            ({ name: [_, fieldName] }) => fieldName === key,
        );

        if (criteria || !isActiveInputType) {
            delete filteredForm[key];
        }
    });

    return filteredForm;
};
