import { FC, PropsWithChildren, ReactNode } from 'react';
import { Col, Row, RowProps } from 'antd';

type CustomRowProps = {
    columnSpan?: number;
    lg?: number;
    md?: number;
    xs?: number;
    rowJustify?: RowProps['justify'];
    rowGutter?: RowProps['gutter'];
    className?: string;
} & PropsWithChildren;

export const CustomRow: FC<CustomRowProps> = ({
    children,
    columnSpan,
    lg,
    md,
    xs,
    rowGutter = 16,
    rowJustify,
    className,
}) => {
    let items: ReactNode;

    if (Array.isArray(children)) {
        items = children
            .filter((element) => !!element)
            .map((element, index) => (
                <Col span={columnSpan} lg={lg} md={md} xs={xs} key={`${index + 1}`}>
                    {element}
                </Col>
            ));
    } else {
        items = (
            <Col span={columnSpan} lg={lg} md={md} xs={xs}>
                {children}
            </Col>
        );
    }

    return (
        <Row gutter={rowGutter} justify={rowJustify} className={className}>
            {items}
        </Row>
    );
};
