import { FC } from 'react';
import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

import { CustomFormItemProps } from '../../types';
import { CustomFormItem } from '../custom-form-item';

export const CustomCheckboxGroup: FC<CustomFormItemProps<CheckboxGroupProps>> = ({
    disabled,
    options,
    defaultValue,
    onChange,
    className,
    ...restProps
}) => (
    <CustomFormItem {...restProps}>
        <Checkbox.Group
            className={className}
            options={options}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
        />
    </CustomFormItem>
);
