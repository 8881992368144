import { useState } from 'react';
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Divider, Dropdown, MenuProps, Space } from 'antd';
import classNames from 'classnames';

import { LogoBlock } from '../logo';

import styles from './header.module.css';

import { AuthType } from '~/constants/auth-type';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { useAppDispatch } from '~/hooks';
import {
    useGetLogoutEsiaUrlMutation,
    usePostLogoutMutation,
} from '~/rtk-queries/endpoints/authentication';
import { useGetFrontConfigQuery } from '~/rtk-queries/endpoints/front-config';
import { useGetUserNameQuery } from '~/rtk-queries/endpoints/user-info';
import { interceptor } from '~/rtk-queries/interceptor';
import { HEADER } from '~/shared/header/constants/header';
import { clearStateOnLogout } from '~/store/auth/auth-slice';
import { FrontConfigKey } from '~/utils/get-front-config/type';

export const Header = () => {
    const [open, setOpen] = useState(false);
    const [postLogout] = usePostLogoutMutation();
    const [postLogoutEsia] = useGetLogoutEsiaUrlMutation();

    const dispatch = useAppDispatch();

    const { data: frontConfig } = useGetFrontConfigQuery();

    const { data: userNameData } = useGetUserNameQuery();
    const name = userNameData?.name;

    const isEsiaAuth = Number(localStorage.getItem(LOCAL_STORAGE.authType)) === AuthType.Esia;

    const logOutHandler = async () => {
        try {
            let esiaLogoutUrl: string | undefined;

            if (isEsiaAuth) {
                esiaLogoutUrl = (await postLogoutEsia().unwrap()).esiaLogoutUrl;
            }

            await postLogout().unwrap();

            localStorage.clear();

            if (isEsiaAuth && esiaLogoutUrl) {
                window.location.href = esiaLogoutUrl;
            } else {
                dispatch(clearStateOnLogout());
                dispatch(interceptor.util.resetApiState());
            }
        } catch (_: unknown) {
            //
        }
    };

    const items: MenuProps['items'] = [
        {
            label: (
                <div className={styles.buttonLogaut}>
                    <div role='presentation' onClick={logOutHandler} className={styles.logoutBlock}>
                        <LogoutOutlined />
                        <span className={styles.headerButton}>{HEADER.buttonTitle}</span>
                    </div>
                </div>
            ),
            style: { padding: 0 },
            key: '0',
        },
    ];

    return (
        <header className={styles.header}>
            <LogoBlock type='dark' />
            <Space className={styles.infoBlock}>
                <a
                    target='_blank'
                    href={
                        frontConfig ? frontConfig[FrontConfigKey.PSBSignatureLink] : HEADER.linkInfo
                    }
                    rel='noopener noreferrer nofollow'
                >
                    <span className={styles.infoLink}>{HEADER.buttonInfo}</span>
                    <Divider className={styles.linkUnderline} />
                </a>
                <Dropdown
                    onOpenChange={() => setOpen(!open)}
                    menu={{ items }}
                    className={styles.dropdownMenu}
                    trigger={['click']}
                >
                    <Space>
                        <UserOutlined className={styles.userOutlined} />
                        <Space className={styles.nameBlock}>{name}</Space>
                        <DownOutlined
                            className={classNames(styles.outLined, { [styles.show]: open })}
                        />
                    </Space>
                </Dropdown>
            </Space>
        </header>
    );
};
