import { API } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { interceptor } from '../interceptor';

import { AlertMessage } from '~/constants/alert-message';
import {
    CertRequestPKIResponse,
    PKICertificateResponse,
    PKIRevokeRequest,
    PKIRevokeResponse,
    PKIUserResponse,
} from '~/rtk-queries/types/pki';

export const pkiInfo = interceptor.injectEndpoints({
    endpoints: (builder) => ({
        getPKIUser: builder.query<PKIUserResponse, string>({
            query: (userId) => ({
                url: `${API.pkiApi}/user/${userId}`,
                method: 'GET',
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),

        getPKICertificate: builder.query<PKICertificateResponse, string>({
            query: (certificateId) => ({
                url: `${API.pkiApi}/certificate/${certificateId}`,
                method: 'GET',
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
        getPKICertRequest: builder.query<CertRequestPKIResponse, string>({
            query: (certRequestId) => ({
                url: `${API.pkiApi}/cert-request/${certRequestId}`,
                method: 'GET',
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
        getPKIRevoke: builder.query<PKIRevokeRequest, string>({
            query: (certificateId) => ({
                url: `${API.pkiApi}/revoke-request/${certificateId}`,
                method: 'GET',
            }),
            transformResponse: (response: PKIRevokeResponse) => response.pkiRevokeRequest,
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
    }),
});

export const {
    useLazyGetPKICertRequestQuery,
    useLazyGetPKICertificateQuery,
    useLazyGetPKIUserQuery,
    useLazyGetPKIRevokeQuery,
} = pkiInfo;
