import dayjs, { Dayjs } from 'dayjs';

import { DATE_FORMAT } from '~/constants/general';

export const checkDraftNullishDate = (initial: Dayjs | string | null, draft?: string) => {
    if (typeof draft === 'undefined') {
        return typeof initial === 'string' ? dayjs(initial, DATE_FORMAT) : initial;
    }

    if (draft === '') {
        return null;
    }

    return dayjs(draft, DATE_FORMAT);
};
