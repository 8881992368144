import { isBoolean } from '../is-boolean';

import { createValidationRules } from './create-validation-rules';

import {
    AddressSectionKey,
    FormSection,
    MainSectionKey,
    OtherSectionKey,
} from '~/constants/application-tab/certificate-form';
import { CommonSectionValidation } from '~/constants/application-tab/validation';
import { UserInfoDataValue } from '~/rtk-queries/types/user-info';
import { UserData } from '~/types/application-tab/certificate-form';
import { PartialRecord } from '~/types/common';

export const getFormSectionFields = <
    T extends MainSectionKey | OtherSectionKey | AddressSectionKey,
>(
    sectionName: Exclude<FormSection, 'document'>,
    data: PartialRecord<T, UserInfoDataValue>,
    sortOrder?: T[],
) => {
    const section = [] as UserData[];

    const sectionEntries = Object.entries(data) as [fieldName: T, value: UserInfoDataValue][];

    sectionEntries.forEach(
        ([fieldName, { enabled, displayName, required, maxLength, minLength, regex }]) => {
            const defaultRules = CommonSectionValidation[sectionName][fieldName];

            section.push({
                name: fieldName,
                label: displayName,
                disabled: isBoolean(enabled) ? !enabled : false,
                mask: defaultRules?.mask ?? '',

                rules: createValidationRules({
                    regex: regex ?? defaultRules?.regex,
                    required: required ?? defaultRules?.required,
                    maxLength,
                    minLength,
                }),
            });
        },
    );

    if (sortOrder) {
        section.sort((a, b) => sortOrder.indexOf(a.name as T) - sortOrder.indexOf(b.name as T));
    }

    return section;
};
