import { UploadFile } from 'antd/es/upload';

export enum CheckDocumentsFields {
    content = 'content',
    source = 'source',
    certVerifiersPluginsIds = 'certVerifiersPluginsIds',
    autoInstallSignatureType = 'autoInstallSignatureType',
    signatureType = 'signatureType',
    signatureSelection = 'signatureSelection',
    rowSignature = 'rowSignature',
    certificate = 'certificate',
    hashFunctionSelection = 'hashFunctionSelection',
}

export type FormTypes = {
    [CheckDocumentsFields.signatureSelection]: number;
    [CheckDocumentsFields.rowSignature]: number;
    [CheckDocumentsFields.content]: UploadFile[];
    [CheckDocumentsFields.certificate]: UploadFile[];
    [CheckDocumentsFields.autoInstallSignatureType]: number;
    [CheckDocumentsFields.source]?: UploadFile[];
    [CheckDocumentsFields.signatureType]?: string;
    [CheckDocumentsFields.certVerifiersPluginsIds]?: number[];
    [CheckDocumentsFields.hashFunctionSelection]?: string;
};
