export const addDotsToDate = (inputArray: HTMLInputElement[]) => {
    const dateChange = (e: KeyboardEvent) => {
        const targetInput = e.target as HTMLInputElement;
        const lengthForDayDot = 2;
        const lengthForMonthDot = 5;
        const dateLength = 10;

        let dateValue = targetInput.value;

        if (['Backspace', 'Delete'].includes(e.key)) {
            return dateValue;
        }
        if (e.key === '.' && dateValue.at(-1)) {
            dateValue = dateValue.slice(0, -2);
        }
        if (dateValue.length === lengthForDayDot || dateValue.length === lengthForMonthDot) {
            dateValue += '.';
            targetInput.value = dateValue;
        }
        if (dateValue.length === dateLength) {
            targetInput.value = dateValue;
        }

        return dateValue;
    };

    inputArray.map((el) => el.addEventListener('keyup', dateChange));
};
